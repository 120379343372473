import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ProductsStockInterface } from "../../../../redux/interfaces/inventory.interface";
import { dateToString, getDateDifference } from "../../../../utils/dateAndTime";

export const productsThatExpireTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Denumire produs",
    value: "numeProdusErp",
    minW: 125,
  },
  {
    label: "Cod produs",
    value: "codProdus",
    minW: 125,
  },
  {
    label: "Lot",
    value: "lot",
    minW: 125,
  },
  {
    label: "Data expirare",
    value: "dataExpirare",
    minW: 125,
  },
  {
    label: "Cantitate",
    value: "totalCantitate",
    minW: 50,
  },
  {
    label: "Locatie",
    value: "typeFilter",
    minW: 100,
    withFilter: [
      {
        label: "Produse care expira in urmatoarele 6 luni",
        value: 1,
      },
      {
        label: "Produse expirate",
        value: 2,
      },
    ],
  },
];

export const getProductsThatExpireTableData: any = (
  inventoryProductsStocksList: ProductsStockInterface[],
) =>
  inventoryProductsStocksList?.map((inventoryProductsStocks) => {
    const isBelowOneMonth =
      getDateDifference(inventoryProductsStocks.dataExpirare) < 1;

    return {
      id: inventoryProductsStocks.idActivCel,
      crudData: inventoryProductsStocks,
      data: [
        {
          title: inventoryProductsStocks.numeProdusErp,
        },
        {
          title: inventoryProductsStocks.codProdus,
        },
        {
          title: inventoryProductsStocks.lot,
        },
        {
          badges: [
            {
              badgeText: `${dateToString(
                inventoryProductsStocks.dataExpirare ?? "-",
              )}`,
              badgeColor: isBelowOneMonth ? "light-danger" : "warning",
            },
          ],
        },
        {
          title: inventoryProductsStocks.cantitate,
        },
        {
          title: inventoryProductsStocks.locatie,
        },
      ],
    };
  });
