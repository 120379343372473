import { createSlice } from "@reduxjs/toolkit";

import {
  fetchUserDetails,
  fetchUserPermissionsMobile,
  fetchUserPermissionsWeb,
  fetchUsers,
  getCellsNomenclature,
  getSelectCellNomenclature,
} from "../api/admin.api";
import {
  CellsNomenclatureInterface,
  PermissionsMobileInterface,
  PermissionsWebInterface,
  SelectCellNomenclatureInterface,
  UserInterface,
  UsersInterface,
} from "../interfaces/admin.interface";

export interface StateAdminInterface {
  users: UsersInterface;
  user: UserInterface;
  permissionsWeb: PermissionsWebInterface;
  permissionsMobile: PermissionsMobileInterface;
  cellsNomenclature: CellsNomenclatureInterface;
  selectCellNomenclature: SelectCellNomenclatureInterface;
}

export const defaultStateAdmin: StateAdminInterface = {
  users: {
    data: {
      nrPagini: 1,
      nrTotal: 1,
      users: [],
    },
    isLoadingUsers: false,
    isErrorUsers: false,
    errorMessageUsers: "",
  },
  user: {
    data: null,
    isLoadingUser: false,
    isErrorUser: false,
    errorMessageUser: "",
  },
  permissionsWeb: {
    data: [],
    isLoadingPermissionsWeb: false,
    isErrorPermissionsWeb: false,
    errorMessagePermissionsWeb: "",
  },
  permissionsMobile: {
    data: [],
    isLoadingPermissionsMobile: false,
    isErrorPermissionsMobile: false,
    errorMessagePermissionsMobile: "",
  },
  cellsNomenclature: {
    data: {
      total: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  selectCellNomenclature: {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const admin = createSlice({
  name: "admin",
  initialState: defaultStateAdmin,
  reducers: {
    removeUserFromUsers(state, action: any) {
      const newUsers = state.users.data.users.filter(
        (user) => user.userId !== action.payload,
      );
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            users: newUsers,
          },
        },
      };
    },
    clearCellNomenclature(state) {
      return {
        ...state,
        selectCellNomenclature: defaultStateAdmin.selectCellNomenclature,
      };
    },
  },
  extraReducers: (builder) => {
    // users start
    builder.addCase(fetchUsers.pending, (state) => {
      return {
        ...state,
        users: {
          ...state.users,
          ...defaultStateAdmin.users,
          isLoadingUsers: true,
        },
      };
    });
    builder.addCase(fetchUsers.rejected, (state, action: any) => {
      return {
        ...state,
        users: {
          ...state.users,
          isLoadingUsers: false,
          isErrorUsers: true,
          errorMessageUsers: action.payload.message,
        },
      };
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      return {
        ...state,
        users: {
          ...state.users,
          ...defaultStateAdmin.users,
          data: action.payload,
        },
      };
    });
    // users end
    // user start
    builder.addCase(fetchUserDetails.pending, (state) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...defaultStateAdmin.user,
          isLoadingUser: true,
        },
      };
    });
    builder.addCase(fetchUserDetails.rejected, (state, action: any) => {
      return {
        ...state,
        user: {
          ...state.user,
          isLoadingUser: false,
          isErrorUser: true,
          errorMessageUser: action.payload.message,
        },
      };
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...defaultStateAdmin.user,
          data: action.payload,
        },
      };
    });
    // user end
    // user permission  web start
    builder.addCase(fetchUserPermissionsWeb.pending, (state) => {
      return {
        ...state,
        permissionsWeb: {
          ...state.permissionsWeb,
          ...defaultStateAdmin.permissionsWeb,
          isLoadingPermissions: true,
        },
      };
    });
    builder.addCase(fetchUserPermissionsWeb.rejected, (state, action: any) => {
      return {
        ...state,
        permissionsWeb: {
          ...state.permissionsWeb,
          isLoadingPermissions: false,
          isErrorPermissions: true,
          errorMessagePermissions: action.payload.message,
        },
      };
    });
    builder.addCase(fetchUserPermissionsWeb.fulfilled, (state, action) => {
      return {
        ...state,
        permissionsWeb: {
          ...state.permissionsWeb,
          ...defaultStateAdmin.permissionsWeb,
          data: action.payload,
        },
      };
    });
    // user permission web end
    // user permissions mobile start
    builder.addCase(fetchUserPermissionsMobile.pending, (state) => {
      return {
        ...state,
        permissionsMobile: {
          ...state.permissionsMobile,
          ...defaultStateAdmin.permissionsMobile,
          isLoadingPermissionsMobile: true,
        },
      };
    });
    builder.addCase(
      fetchUserPermissionsMobile.rejected,
      (state, action: any) => {
        return {
          ...state,
          permissionsMobile: {
            ...state.permissionsMobile,
            isLoadingPermissionsMobile: false,
            isErrorPermissionsMobile: true,
            errorMessagePermissionsMobile: action.payload.message,
          },
        };
      },
    );
    builder.addCase(fetchUserPermissionsMobile.fulfilled, (state, action) => {
      return {
        ...state,
        permissionsMobile: {
          ...state.permissionsMobile,
          ...defaultStateAdmin.permissionsMobile,
          data: action.payload,
        },
      };
    });
    // user permissions mobile end

    // cells nomenclature start
    builder.addCase(getCellsNomenclature.pending, (state) => {
      return {
        ...state,
        cellsNomenclature: {
          ...defaultStateAdmin.cellsNomenclature,
          isLoading: true,
        },
      };
    });
    builder.addCase(getCellsNomenclature.rejected, (state, action: any) => {
      return {
        ...state,
        cellsNomenclature: {
          ...state.cellsNomenclature,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getCellsNomenclature.fulfilled, (state, action) => {
      return {
        ...state,
        cellsNomenclature: {
          ...state.cellsNomenclature,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // cells nomenclature end

    // select cell nomenclature start
    builder.addCase(getSelectCellNomenclature.pending, (state) => {
      return {
        ...state,
        selectCellNomenclature: {
          ...defaultStateAdmin.selectCellNomenclature,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      getSelectCellNomenclature.rejected,
      (state, action: any) => {
        return {
          ...state,
          selectCellNomenclature: {
            ...state.selectCellNomenclature,
            isLoading: false,
            errorMessage: action.payload.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(getSelectCellNomenclature.fulfilled, (state, action) => {
      return {
        ...state,
        selectCellNomenclature: {
          ...state.selectCellNomenclature,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // select cell nomenclature end
  },
});

export const { removeUserFromUsers, clearCellNomenclature } = admin.actions;

export default admin.reducer;
