import React from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getCellsNomenclature } from "../../../redux/api/admin.api";

import { TableActions } from "../../../components/tables/tableContext/TableContext";
import {
  cellsNomenclatureTableHeader,
  getCellsNomenclatureTableData,
} from "./tableConfigs/cellsNomenclatureTableConfig";

import CellsNomenclatureCustomHeader from "./components/CellsNomenclatureCustomHeader";
import CellsNomenclatureActionButton from "./tableConfigs/CellsNomenclatureActionButtons";

function CellsNomenclature() {
  const dispatch = useAppDispatch();

  const {
    admin: {
      cellsNomenclature: {
        data: { data: cellsNomenclatureData, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getCellsNomenclature({
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          cladire: action.tableSearch.cladire,
          alee: action.tableSearch.alee,
          celula: action.tableSearch.celula,
          coloana: action.tableSearch.coloana,
          insula: action.tableSearch.insula,
          polita: action.tableSearch.polita,
          rand: action.tableSearch.rand,
          zona: action.tableFilter.zona,
          type: action.tableFilter.type,
        }),
      );
    }
  };

  return (
    <TableWidget
      tableHeaderData={cellsNomenclatureTableHeader}
      tableItemsData={getCellsNomenclatureTableData(cellsNomenclatureData)}
      handleTableActions={handleTableActions}
      CustomHeaderComponent={CellsNomenclatureCustomHeader}
      ActionButtonsComponent={(props: any) => (
        <CellsNomenclatureActionButton {...props} />
      )}
      tableLoading={isLoading}
      borderedRow
      totalItems={total}
    />
  );
}

export default CellsNomenclature;
