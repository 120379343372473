import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import TableWidget from "../../../components/tables/TableWidget";
import {
  getSearchPackageByOpTableData,
  searchPackageByOpTableHeader,
} from "./tableConfigs/searchPackageByOpTableConfig";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { getScannedPackageByOp } from "../../../redux/api/expedition.api";

function SearchPackageByOp() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      searchPackageByOp: {
        data: { data: scannedPackageByOp, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    )
      if (action.tableSearch.op) {
        dispatch(
          getScannedPackageByOp({
            page: action.tablePagination.page,
            per_page: action.tablePagination.rowsPerPage,
            op: action.tableSearch.op,
          }),
        );
      }
  };

  return (
    <TableWidget
      tableTitle="Cautare colet dupa OP"
      tableHeaderData={searchPackageByOpTableHeader}
      tableItemsData={getSearchPackageByOpTableData(scannedPackageByOp)}
      totalItems={total}
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
      withTablePagination
    />
  );
}

export default SearchPackageByOp;
