import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import * as Yup from "yup";
import ContentCard from "../../../../components/misc/ContentCard";
import { Box } from "@mui/material";
import TextInput from "../../../../components/misc/TextInput";
import CustomButton from "../../../../components/CustomButton";
import {
  getSelectVerificationZone,
  postCreateOrUpdateVerificationZone,
} from "../../../../redux/api/picking.api";
import { useNavigate } from "react-router-dom";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";
import { clearVerificationZone } from "../../../../redux/reducer/picking.reducer";

interface initialValuesInterface {
  ipImprimanta: string | null;
  portImprimanta: number | null;
  ip: string | null;
  portZebra: number | null;
  codZona?: string | null;
}

const initialValues: initialValuesInterface = {
  codZona: null,
  ipImprimanta: null,
  portImprimanta: null,
  ip: null,
  portZebra: null,
};

// Validation schema
const productSchema = Yup.object({
  codZona: Yup.string().nullable().required("Campul este obligatoriu"),
  ipImprimanta: Yup.string().nullable().required("Campul este obligatoriu"),
  portImprimanta: Yup.string().nullable().required("Campul este obligatoriu"),
  ip: Yup.string().nullable().required("Campul este obligatoriu"),
  portZebra: Yup.string().nullable().required("Campul este obligatoriu"),
});

function CreateOrUpdateVerificationZone() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    picking: {
      selectVerificationZone: {
        data: verificationZonesData,
        isLoading: isLoadingVerifycationZones,
      },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const { routeParams } = useRoutesHelper();
  const { idZona } = routeParams || {};

  const [isLoading, setIsLoading] = useState(false);
  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  useEffect(() => {
    if (idZona) {
      dispatch(getSelectVerificationZone(idZona));
    }
  }, [dispatch, idZona]);

  useEffect(() => {
    if (verificationZonesData) {
      setFormikValues((prev) => ({
        ...prev,
        codZona: verificationZonesData?.codZona || null,
        ip: verificationZonesData?.ip || null,
        ipImprimanta: verificationZonesData?.ipImprimanta || null,
        portImprimanta: verificationZonesData?.portImprimanta || null,
        portZebra: verificationZonesData?.portZebra || null,
      }));
    }
  }, [verificationZonesData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveVerificationZone = async (values: initialValuesInterface) => {
    const payload = {
      idZona,
      ...values,
    };

    setIsLoading(true);

    const resultAction = await dispatch(
      postCreateOrUpdateVerificationZone({ params: payload }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateVerificationZone) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateVerificationZone) ===
      endpointStatus.fulfilled
    ) {
      handleGoBack();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearVerificationZone());
    };
  }, [dispatch]);

  return (
    <>
      <ContentCard
        cardTitle={`${idZona ? " Editeaza zona" : "Creeaza zona"}`}
        isLoading={isLoadingVerifycationZones}>
        <Formik
          enableReinitialize
          initialValues={formikValues}
          validationSchema={productSchema}
          onSubmit={handleSaveVerificationZone}>
          {({
            isValid,
            errors,
            touched,
            getFieldProps,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                noValidate
                id="kt_login_signin_form">
                <TextInput
                  className="mb-4 me-2"
                  label="Cod zona"
                  name="codZona"
                  error={errors["codZona"]}
                  touched={touched["codZona"]}
                  inputProps={getFieldProps("codZona")}
                  disabled={!!idZona}
                />
                <Box className="d-flex">
                  <TextInput
                    className="mb-4 me-2"
                    label="Ip imprimanta"
                    name="ipImprimanta"
                    error={errors["ipImprimanta"]}
                    touched={touched["ipImprimanta"]}
                    inputProps={getFieldProps("ipImprimanta")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Port imprimanta"
                    name="portImprimanta"
                    error={errors["portImprimanta"]}
                    touched={touched["portImprimanta"]}
                    inputProps={getFieldProps("portImprimanta")}
                  />
                </Box>
                <Box className="d-flex">
                  <TextInput
                    className="mb-4 me-2"
                    label="Ip zebra"
                    name="ip"
                    error={errors["ip"]}
                    touched={touched["ip"]}
                    inputProps={getFieldProps("ip")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Port zebra"
                    name="portZebra"
                    error={errors["portZebra"]}
                    touched={touched["portZebra"]}
                    inputProps={getFieldProps("portZebra")}
                  />
                </Box>
                <div className="d-flex justify-content-between">
                  <CustomButton variant="outlined" onClick={handleGoBack}>
                    Anuleaza
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || isLoading}
                    disabled={!isValid || isSubmitting}>
                    Salveaza
                  </CustomButton>
                </div>
              </form>
            );
          }}
        </Formik>
      </ContentCard>
    </>
  );
}

export default CreateOrUpdateVerificationZone;
