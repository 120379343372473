import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
import ContentCard from "../../components/misc/ContentCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TextInput from "../../components/misc/TextInput";
import CustomButton from "../../components/CustomButton";
import Dropdown from "../../components/misc/Dropdown";
import { fetchCreateOrUpdateProductWithCustomSN } from "../../redux/api/receipt.api";
import { checkEndpointStatus, endpointStatus } from "../../helpers/apiHelper";
import { receptionRoutes } from "../../router/routesConstants";

interface initialValuesInterface {
  id: number | null;
  idProdusErp: number | null;
  codGrupa: string;
  codProdus: string;
  numeGrupa: string;
  numeProdus: string;
  sn: string;
  tip: number;
  codInternVanzare?: string;
}

const productTypes = [
  { id: 1, label: "Fara sn" },
  { id: 2, label: "AIP" },
  { id: 3, label: "IP" },
];

const initialValues: initialValuesInterface = {
  id: null,
  idProdusErp: null,
  codGrupa: "",
  codProdus: "",
  numeGrupa: "",
  numeProdus: "",
  sn: "",
  tip: productTypes[0].id,
  codInternVanzare: "",
};

const productSchema = Yup.object({
  id: Yup.number().nullable(),
  idProdusErp: Yup.number().required("Id Produs ERP este obligatoriu"),
  codGrupa: Yup.string().required("Cod Grupa este obligatoriu"),
  codProdus: Yup.string().required("Cod Produs este obligatoriu"),
  numeGrupa: Yup.string().required("Nume Grupa este obligatoriu"),
  numeProdus: Yup.string().required("Nume Produs este obligatoriu"),
  sn: Yup.string(),
  tip: Yup.number().required("Tip este obligatoriu"),
  codInternVanzare: Yup.string(),
});

function CreateProductsWithCustomBc() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    receipt: { createProductWithCustomSn },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (createProductWithCustomSn) {
      setFormikValues((prev) => ({
        ...prev,
        id: createProductWithCustomSn?.id,
        idProdusErp: createProductWithCustomSn?.idProdusErp,
        codGrupa: createProductWithCustomSn?.codGrupa,
        codProdus: createProductWithCustomSn?.codProdus,
        numeGrupa: createProductWithCustomSn?.numeGrupa,
        numeProdus: createProductWithCustomSn?.numeProdus,
        sn: createProductWithCustomSn?.sn,
      }));
    }
  }, [createProductWithCustomSn]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      fetchCreateOrUpdateProductWithCustomSN({
        ...createProductWithCustomSn,
        ...value,
      }),
    );

    if (
      checkEndpointStatus(
        resultAction,
        fetchCreateOrUpdateProductWithCustomSN,
      ) !== endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(
        resultAction,
        fetchCreateOrUpdateProductWithCustomSN,
      ) === endpointStatus.fulfilled
    ) {
      navigate(`/${receptionRoutes.productsWithCustomBc}`);
    }
  };

  if (!createProductWithCustomSn?.numeProdus) {
    return <ContentCard cardTitle="Nu exista acest produs" />;
  }

  return (
    <ContentCard
      cardTitle={
        createProductWithCustomSn?.id
          ? "Editeaza cod de bare"
          : "Creeaza cod de bare"
      }>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={productSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          values,
          touched,
          getFieldProps,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          console.log(errors);
          const selectedType = productTypes.find(
            (prouctType) => prouctType.id === values.tip,
          );
          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <TextInput
                label="Nume Produs"
                name="numeProdus"
                error={errors["numeProdus"]}
                touched={touched["numeProdus"]}
                inputProps={getFieldProps("numeProdus")}
                disabled={!createProductWithCustomSn?.id}
              />

              <Dropdown
                className="my-4"
                title={"Tip"}
                data={productTypes}
                singleValue={
                  selectedType || {
                    label: "",
                    id: "",
                  }
                }
                setSingleValue={(value) => {
                  setFieldValue("tip", value?.id);
                }}
                error={errors["tip"]}
                touched={touched["tip"]}
              />
              <div className="d-flex">
                {productTypes[1].id === values.tip && (
                  <TextInput
                    className="ms-2"
                    label="Cod intern vanzare"
                    name="codInternVanzare"
                    error={errors["codInternVanzare"]}
                    touched={touched["codInternVanzare"]}
                    inputProps={getFieldProps("codInternVanzare")}
                  />
                )}
                {createProductWithCustomSn?.id && (
                  <TextInput
                    label="Serial number"
                    name="sn"
                    error={errors["sn"]}
                    touched={touched["sn"]}
                    inputProps={getFieldProps("sn")}
                  />
                )}
              </div>

              <div className="d-flex justify-content-between">
                <CustomButton
                  type="submit"
                  variant="outlined"
                  onClick={handleGoBack}>
                  Anuleaza
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || isLoading}
                  disabled={!isValid || isSubmitting}>
                  Salveaza
                </CustomButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default CreateProductsWithCustomBc;
