import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  DestroyedProductsType,
  QuarantinedProductsStockInterface,
} from "../../../../redux/interfaces/inventory.interface";
import { postProdToDestroy } from "../../../../redux/api/inventory.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

import CustomButton from "../../../../components/CustomButton";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import TextInput from "../../../../components/misc/TextInput";
import Text from "../../../../styleguide/Text";

import Dropdown, { DropdownValue } from "../../../../components/misc/Dropdown";
import ContentCard from "../../../../components/misc/ContentCard";
import { destroyProductReasons } from "../../../../constants/general";

const DestroyProductsModal = ({
  selectedProductsToDestroy,
  setSelectedItems,
  reason,
}: {
  selectedProductsToDestroy: QuarantinedProductsStockInterface[];
  setSelectedItems: any;
  reason?: number;
}) => {
  const dispatch = useAppDispatch();
  const [isDestroyModalOpen, setIsDestroyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productsToDestroy, setProductsToDestroy] = useState<
    DestroyedProductsType[]
  >([]);

  useEffect(() => {
    const selectedProductsToDestroyWithReason = selectedProductsToDestroy.map(
      (product) => {
        return {
          ...product,
          quantityToDestroy: null,
          reason: destroyProductReasons[0],
        };
      },
    );

    setProductsToDestroy(selectedProductsToDestroyWithReason);
  }, [selectedProductsToDestroy]);

  const handleDestroyProductsModalShow = (show: boolean) => {
    setIsDestroyModalOpen(show || false);
    return;
  };

  const handleChangeProductQuantity = (
    id: string,
    value: string | number | null,
  ) => {
    setProductsToDestroy((prev) => {
      return prev.map((product) => {
        if (id === product.id) {
          if (Number(value) <= product.cantitate && Number(value) >= 0) {
            return {
              ...product,
              quantityToDestroy: value ? Number(value) : null,
            };
          }
        }
        return product;
      });
    });
  };

  const handleChangeProductDestroyReason = (
    id: string,
    value: DropdownValue | null,
  ) => {
    setProductsToDestroy((prev) => {
      return prev.map((product) => {
        if (id === product.id) {
          return {
            ...product,
            reason: value,
          };
        }
        return product;
      });
    });
  };

  const handleSaveDestroyedProducts = async () => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postProdToDestroy({
        productsToDestroy: productsToDestroy,
        page: 1,
        perPage: 1000,
        reason: reason,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postProdToDestroy) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postProdToDestroy) ===
      endpointStatus.fulfilled
    ) {
      setSelectedItems([]);
    }
  };

  const isSubmitDisabled = productsToDestroy?.some(
    (product) => !product.quantityToDestroy,
  );

  return (
    <>
      <CustomButton
        onClick={() => handleDestroyProductsModalShow(true)}
        variant="contained">
        Distruge
      </CustomButton>
      <ModalWrapper
        title="Distrugere produse"
        modalSize="xl"
        fullscreen="lg-down"
        show={isDestroyModalOpen}
        handleClose={() => handleDestroyProductsModalShow(false)}>
        {productsToDestroy?.map((product) => {
          return (
            <ContentCard
              key={product.idActivCel}
              cardTitle={product.numeProdusErp}>
              <div className="d-flex">
                <TextInput
                  type="number"
                  name={product.numeProdusErp}
                  label={"Cantitate"}
                  value={product.quantityToDestroy}
                  placeholder="Cantitate de distrus"
                  onChange={(value) =>
                    handleChangeProductQuantity(product.id, value)
                  }
                  endComponent={
                    <Text
                      variant="body1"
                      color={"royalblue"}
                      className="rounded bg-white p-2">
                      Cantitate totala: {product.cantitate}
                    </Text>
                  }
                />
                <Dropdown
                  title="Motivul distrugerii"
                  className="ms-2"
                  data={destroyProductReasons}
                  singleValue={product.reason}
                  setSingleValue={(value) =>
                    handleChangeProductDestroyReason(product.id, value)
                  }
                />
              </div>
            </ContentCard>
          );
        })}

        <CustomButton
          type="submit"
          variant="contained"
          fullWidth
          className="btn btn-lg btn-primary w-100 mt-5"
          disabled={isSubmitDisabled}
          loading={isLoading}
          onClick={handleSaveDestroyedProducts}>
          <Text variant="body1" className="indicator-label" isBold>
            Salveaza
          </Text>
        </CustomButton>
      </ModalWrapper>
    </>
  );
};

export default DestroyProductsModal;
