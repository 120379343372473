import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getProductsDestroyedRaports } from "../../../redux/api/inventory.api";

import {
  getProductsDestroyedReportsTableData,
  productsDestroyedReportsTableHeader,
} from "./tableConfigs/productsDestroyedReportsConfig";

import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import ProductsDestroyedRaportsActionButton from "./TableActionsButtonsComponents/ProductsDestroyedRaportsActionButton";
import { dateForDatabase } from "../../../utils/dateAndTime";

function ProductsDestroyedRaports() {
  const dispatch = useAppDispatch();
  const {
    inventory: {
      productsDestroyedRaports: {
        data: { data: productsDestroyedRaportsList, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      const formatDate = dateForDatabase(action.tableSearch.dataPV);

      dispatch(
        getProductsDestroyedRaports({
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          user: action.tableSearch.user,
          dataPV: formatDate,
          productName: action.tableSearch.productName,
          lot: action.tableSearch.lot,
        }),
      );
    }
  };

  const sortedProductsDestroyedRaportsList = [
    ...productsDestroyedRaportsList,
    // @ts-ignore
  ].sort((a, b) => {
    if (a.dataPV === b.dataPV) {
      if (a.nr_raport > b.nr_raport) {
        return -1;
      }
      if (a.nr_raport < b.nr_raport) {
        return 1;
      }
      return 0;
    }
  });

  return (
    <TableWidget
      tableTitle={"Rapoarte distrugere produse"}
      tableHeaderData={productsDestroyedReportsTableHeader}
      tableItemsData={getProductsDestroyedReportsTableData(
        sortedProductsDestroyedRaportsList,
      )}
      totalItems={total}
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
      ActionButtonsComponent={(props: any) => (
        <ProductsDestroyedRaportsActionButton {...props} />
      )}
      withGlobalSearch={false}
      withGlobalFilter={false}
    />
  );
}

export default ProductsDestroyedRaports;
