import { DropdownValue } from "../components/misc/Dropdown";

export const destroyProductReasons: DropdownValue[] = [
  {
    id: 1,
    label: "Expirat",
  },
  {
    id: 2,
    label: "Deteriorat",
  },
  {
    id: 3,
    label: "Produs distrus din retragere",
  },
];

export const returningProductTypes: DropdownValue[] = [
  {
    id: 1,
    label: "Aviz de insotire a marfii",
  },
  {
    id: 2,
    label: "Factura de retur",
  },
];
