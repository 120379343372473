import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { parseDrugCode } from "../../../../helpers/commonHelper";
import { QuarantinedProductsStockInterface } from "../../../../redux/interfaces/inventory.interface";
import { dateToString } from "../../../../utils/dateAndTime";

const reasonsOfQuarantinedProducts = [
  { value: 4, label: "Carantina" },
  { value: 5, label: "Retrageri" },
  { value: 6, label: "Returnari" },
  { value: 7, label: "Neconforme" },
  { value: 8, label: "Falsificate" },
  { value: 9, label: "Expirate" },
];

const getQuarantinedReason = (status: any) => {
  const currentStatus = reasonsOfQuarantinedProducts.find(
    (item) => item.value === status,
  );

  return currentStatus?.label;
};

export const quarantinedProductsToDestroyTableHeader: TableHeaderCategoryConfig[] =
  [
    {
      label: "",
      value: "selected",
      minW: 125,
    },
    {
      label: "Denumire produs",
      value: "numeProdusErp",
      minW: 125,
    },
    {
      label: "Cod grupa",
      value: "codGrupa",
      minW: 125,
    },
    {
      label: "Cod produs",
      value: "codProdus",
      minW: 125,
    },
    {
      label: "Lot",
      value: "lot",
      minW: 125,
    },
    {
      label: "Data expirare",
      value: "dataExpirare",
      minW: 125,
    },
    {
      label: "Cantitate",
      value: "cantitate",
      minW: 50,
    },
    {
      label: "Motiv",
      value: "reason",
      minW: 50,
      withFilter: reasonsOfQuarantinedProducts,
    },
    {
      label: "Locatie",
      value: "locatie",
      minW: 100,
    },
  ];

export const getQuarantinedProductsToDestroyTableData: any = (
  inventoryQuarantinedProductsStocksListList: QuarantinedProductsStockInterface[],
) =>
  inventoryQuarantinedProductsStocksListList?.map((item) => {
    return {
      id: item.idActivCel,
      crudData: item,
      data: [
        {
          checkbox: true,
        },
        {
          title: item.numeProdusErp,
        },
        {
          title: item.codGrupa ? parseDrugCode(item.codGrupa) : "-",
        },
        {
          title: item.codProdus,
        },
        {
          title: item.lot,
        },
        {
          badges: [
            {
              badgeText: `${dateToString(item.dataExpirare ?? "-")}`,
              badgeColor: "light-danger",
            },
          ],
        },
        {
          title: item.cantitate,
        },
        {
          badges: [
            {
              badgeText: getQuarantinedReason(item.motiv),
              badgeColor: "light-info",
            },
          ],
        },
        {
          title: item.locatie,
        },
      ],
    };
  });
