import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";
import { AddAllDaysItemScheduleInterface } from "../../pages/Expedition/components/ConfigWorkingDays";

import {
  BorderouHistoryListInterface,
  PlasticBoxesListInterface,
  ScheduleWorkingRoutesListInterface,
} from "../interfaces/expedition.interface";
import { DropdownValue } from "../../components/misc/Dropdown";
import { setAddPlasticBox } from "../reducer/expedition.reducer";
import { setGlobalToast } from "../reducer/globalComponents.reducer";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const getScheduleWorkingPoints = createAsyncThunk(
  "/expedition/scheduleWorkingPoints",
  async (
    params: {
      page?: number;
      perPage?: number;
      deliveryPoint: string;
      route: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, deliveryPoint } = params;

    try {
      const { data } = await apiRequest(
        `/puncteLucru?page=${page}&per_page=${perPage}&searchPctLucru=${deliveryPoint}&searchRuta=${params.route}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/scheduleWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScheduleWorkingPointsDetails = createAsyncThunk(
  "/expedition/getScheduleWorkingPointsDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectPctLucru/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getScheduleWorkingPointsDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postScheduleWorkingDays = createAsyncThunk(
  "/expedition/addScheduleWorkingDays",
  async (
    params: {
      transferData: {
        activeWorkingDays: AddAllDaysItemScheduleInterface[];
        id: number;
        selectedWorkingRoute: ScheduleWorkingRoutesListInterface;
      };
      callback: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { transferData, callback } = params;
    try {
      const { data } = await apiRequest(
        "/addProgramPctLucru",
        "POST",
        transferData,
      );
      callback();
      return data;
    } catch (err: any) {
      console.warn("/expedition/addScheduleWorkingDays", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScheduleWorkingRoutes = createAsyncThunk(
  "/expedition/getScheduleWorkingRoutes",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage } = params;

    try {
      const { data } = await apiRequest(
        `/rute?page=${page}&per_page=${perPage}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/getScheduleWorkingRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScheduleWorkingRoutesDetails = createAsyncThunk(
  "/expedition/getScheduleWorkingRoutesDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectRuta/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getScheduleWorkingRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postScheduleRoutes = createAsyncThunk(
  "/expedition/postScheduleRoutes",
  async (
    params: {
      transferData: {
        activeWorkingDays: AddAllDaysItemScheduleInterface[];
        internode: boolean;
        id: number;
        selectedNode: DropdownValue;
      };
      callback: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { transferData, callback } = params;
    try {
      const { data } = await apiRequest(
        "/addProgramRute",
        "POST",
        transferData,
      );
      callback();
      return data;
    } catch (err: any) {
      console.warn("/expedition/postScheduleRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutesNodes = createAsyncThunk(
  "/expedition/getWorkingRoutesNodes",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`noduri`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutesNodes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getGenerateBarcodeNodeDetails = createAsyncThunk(
  "/expedition/getGenerateBarcodeNodeDetails",
  async (
    {
      callBack,
      idNode,
    }: {
      callBack?: () => void;
      idNode: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`generateBarcode/${idNode}`, "GET");
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/expedition/getGenerateBarcodeNodeDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getGenerateBorderouNodeDetails = createAsyncThunk(
  "/expedition/getGenerateBorderouNodeDetails",
  async (
    {
      callBack,
      nodeName,
    }: {
      callBack?: () => void;
      nodeName: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`borderou/${nodeName}`, "GET");
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/expedition/getGenerateBorderouNodeDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditNodeDetails = createAsyncThunk(
  "/expedition/postEditNodeDetails",
  async (
    {
      callBack,
      params,
    }: {
      callBack?: () => void;
      params: {
        nodId: number;
        auto: string;
        sofer: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("editNod", "POST", params);
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/expedition/postEditNodeDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPreviewBorderou = (nodNume: string | number) => {
  if (!window) {
    return;
  }
  window
    .open(`https://wms.ssnt.ro/public/api/borderouPreview/${nodNume}`, "_blank")
    ?.focus();
};

export const getIncompleteInvoices = createAsyncThunk(
  "/expedition/getIncompleteInvoices",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest("/facturiNelucrate", "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getIncompleteInvoices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScannedPlasticBox = createAsyncThunk(
  "/expedition/getScannedPlasticBox",
  async (
    {
      sn,
      type,
    }: {
      sn: string;
      type: string;
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    const state: any = getState();
    const plasticBoxesList: PlasticBoxesListInterface[] =
      state.expedition.plasticBoxesScanned.data;

    const alreadyInList = plasticBoxesList.some((box) => box.sn === sn);

    if (alreadyInList) {
      dispatch(
        setGlobalToast({
          open: true,
          vertical: "bottom",
          horizontal: "right",
          message: "Cutia a fost deja scanata",
          severity: "error",
        }),
      );
      return;
    }

    try {
      const { data } = await apiRequest(
        `valideazaCutiePlastic/${sn}?type=${type}`,
        "GET",
      );

      dispatch(setAddPlasticBox(data));

      return data;
    } catch (err: any) {
      console.warn("/expedition/getScannedPlasticBox", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postMonitoringPlasticBoxes = createAsyncThunk(
  "/expedition/postMonitoringPlasticBoxes",
  async (
    {
      params,
    }: {
      params: {
        page: number;
        per_page: number;
        status: string;
        ruta: string;
        pctDeLivr: string;
        sn: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/selectCutiiPlastic", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/postMonitoringPlasticBoxes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postBorderouHistory = createAsyncThunk(
  "/expedition/postBorderouHistory",
  async (
    {
      params,
    }: {
      params: {
        dataBorderou: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data }: { data: BorderouHistoryListInterface[] } =
        await apiRequest("/listaBorderouri", "POST", params);

      return data;
    } catch (err: any) {
      console.warn("/expedition/postBorderouHistory", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDownloadBorderou = createAsyncThunk(
  "/expedition/getDownloadBorderou",
  async (
    {
      idBorderou,
      nod,
    }: {
      idBorderou: number;
      nod: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(`${apiEnv}/borderouDownload/${idBorderou}?nod=${nod}`, "_blank")
        ?.focus();
    } catch (err: any) {
      console.warn("/expedition/getDownloadBorderou", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDownloadPlasticBoxes = createAsyncThunk(
  "/expedition/getDownloadPlasticBoxes",
  async (
    {
      page,
      per_page,
      status,
      pctDeLivr,
      ruta,
      sn,
    }: {
      page: number;
      per_page: number;
      status: string;
      ruta: string;
      pctDeLivr: string;
      sn: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(
          `${apiEnv}/exportCutiiPlastic?sn=${sn}=page=${page}&per_page=${per_page}&status=${status}&pctDeLivr=${pctDeLivr}&ruta=${ruta}`,
          "_blank",
        )
        ?.focus();
    } catch (err: any) {
      console.warn("/expedition/getDownloadPlasticBoxes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScannedPackageByOp = createAsyncThunk(
  "/picking/getScannedPackageByOp",
  async (
    params: {
      page: number;
      per_page: number;
      op: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/getBoxByOrderPicking", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/picking/getScannedPackageByOp", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
