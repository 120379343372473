import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getProductsWithoutSnTableData,
  productsWithoutSnTableHeader,
} from "./tableConfigs/productsWithoutSnTableConfig";
import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import { fetchProductsWithoutSN } from "../../../redux/api/receipt.api";
import { clearProductsWithoutSN } from "../../../redux/reducer/receipt.reducer";
import ProductsWithoutSnActionButtons from "./TableActionsButtonsComponents/ProductsWithoutSnActionButtons";

function ProductsWithoutSn() {
  const dispatch = useAppDispatch();

  const {
    receipt: {
      productsWithoutSN: {
        data: { data: productsWithoutSN, total: totalProducts },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearProductsWithoutSN());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchProductsWithoutSN({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          numeProdus: action.tableSearch?.numeProdus,
          codProdus: action.tableSearch?.codProdus,
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle="Produse fara SN"
      tableHeaderData={productsWithoutSnTableHeader}
      tableItemsData={getProductsWithoutSnTableData(productsWithoutSN)}
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
      totalItems={totalProducts}
      ActionButtonsComponent={(props: any) => (
        <ProductsWithoutSnActionButtons {...props} />
      )}
    />
  );
}

export default ProductsWithoutSn;
