import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";

import { UsersExtraActivitiesInterface } from "../../../../redux/interfaces/reports.interface";

export const usersExtraActivitiesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Username",
    value: "userName",
    minW: 125,
  },
  {
    label: "Prenume",
    value: "prenume",
    minW: 125,
  },
  {
    label: "Nume",
    value: "nume",
    minW: 125,
  },
  {
    label: "Aranjare paleti (ore)",
    value: "aranjarePaleti",
    minW: 125,
  },
  {
    label: "Descarcare paleti (ore)",
    value: "descarcarePaleti",
    minW: 125,
  },
  {
    label: "Repaletare (ore)",
    value: "repaletare",
    minW: 125,
  },
  {
    label: "Descarcare masini (ore)",
    value: "descarcareMasini",
    minW: 125,
  },
  {
    label: "Transfer stoc (ore)",
    value: "transferStoc",
    minW: 125,
  },
  {
    label: "Transfer marfa (ore)",
    value: "transferMarfa",
    minW: 125,
  },
];

export const getUsersExtraActivitiesTableData: any = (
  extraActivitiesList: UsersExtraActivitiesInterface[],
) =>
  extraActivitiesList?.map((item) => {
    return {
      id: item.idUser,
      crudData: item,
      data: [
        {
          title: item.userName,
        },
        {
          title: item.prenume,
        },
        {
          title: item.nume,
        },
        {
          title: item.aranjarePaleti ?? "-",
        },
        {
          title: item.descarcarePaleti ?? "-",
        },
        {
          title: item.repaletare ?? "-",
        },
        {
          title: item.descarcareMasini ?? "-",
        },
        {
          title: item.transferStoc ?? "-",
        },
        {
          title: item.transferMarfa ?? "-",
        },
      ],
    };
  });
