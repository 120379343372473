import React, { useEffect } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getReturningProductsToProviderTableData,
  returningProductsToProviderTableHeader,
} from "./tableConfigs/returningProductsToProviderTableConfig";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getQuarantinedProductsToReturn } from "../../../redux/api/inventory.api";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { clearQuarantinedProducts } from "../../../redux/reducer/inventory.reducer";
import useTableCtx from "../../../components/tables/tableContext/useTableCtx";
import CustomButton from "../../../components/CustomButton";
import ReturningProductsModal from "./components/ReturningProductsModal";

function ReturningProductsToProvider() {
  const dispatch = useAppDispatch();
  const {
    inventory: {
      quarantinedProductsToReturn: {
        data: { data: returningProducts, total: productsTotalNumber },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getQuarantinedProductsToReturn({
          page: 1,
          perPage: 10000,
        }),
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearQuarantinedProducts());
    };
  }, [dispatch]);

  const CustomHeaderComponent = () => {
    const { handleCheckAllItems, selectedItems, setSelectedItems } =
      useTableCtx() || {};

    return (
      <div className="mb-5">
        <CustomButton
          className="me-2"
          onClick={() => handleCheckAllItems && handleCheckAllItems()}>
          Selecteaza toate
        </CustomButton>
        {selectedItems && selectedItems.length > 0 && (
          <ReturningProductsModal
            selectedReturningProducts={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        )}
      </div>
    );
  };

  return (
    <TableWidget
      tableTitle={
        "Produse carantinate, eligibile pentru returnare catre furnizor"
      }
      CustomHeaderComponent={CustomHeaderComponent}
      tableHeaderData={returningProductsToProviderTableHeader}
      tableItemsData={getReturningProductsToProviderTableData(
        returningProducts,
      )}
      handleTableActions={handleTableActions}
      totalItems={productsTotalNumber}
      tableLoading={isLoading}
      withGlobalSearch={false}
      withGlobalFilter={false}
      withTablePagination={false}
    />
  );
}

export default ReturningProductsToProvider;
