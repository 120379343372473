import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";
import {
  InvoiceDetailsItem,
  NrcdDetailsListInterface,
} from "../interfaces/receipt.interface";
import {
  closeGlobalLoading,
  closeGlobalToast,
  setGlobalLoading,
  setGlobalToast,
} from "../reducer/globalComponents.reducer";
import { toggleProductWithCustomSnValidation } from "../reducer/receipt.reducer";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

const handleSetGlobalLoading = (
  dispatch: any,
  open = false,
  title?: string,
  subtitle?: string,
) => {
  if (open) {
    dispatch(setGlobalLoading({ open, title, subtitle }));
  } else {
    dispatch(closeGlobalLoading());
  }
};

const handleSetGlobalToast = (
  dispatch: any,
  message?: string,
  severity = "success",
) => {
  if (message) {
    dispatch(setGlobalToast({ message, severity }));
  } else {
    dispatch(closeGlobalToast());
  }
};

export const getNomenclature = createAsyncThunk(
  "/receipt/nomenclature",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest("/nomenclatoare", "GET");
      return data;
    } catch (err: any) {
      console.warn("/receipt/nomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postStartReceipt = createAsyncThunk(
  "/receipt/start",
  async (
    params: {
      userId: number | string;
      idFurnizor: number | string;
      idZonaReceptie: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/startReceptie", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/receipt/start", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReceipts = createAsyncThunk(
  "/receipt/getReceipts",
  async (
    filters: {
      stadiu: string | number;
      page?: number;
      perPage?: number;
      provider?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { stadiu, page, perPage, provider } = filters;
    try {
      const { data } = await apiRequest(
        `/veziReceptie?page=${page ?? 1}&per_page=${
          perPage ?? 15
        }&status=${stadiu}&searchFurnizor=${provider ?? ""}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/receipt/getReceipts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getProductDetailsById = createAsyncThunk(
  "/product",
  async (productId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/productDetails/${productId}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/product", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReceiptsDataList = createAsyncThunk(
  "/receipt/productsByIdFlux",
  async (idFlux: number | string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/productsByIdFlux/${idFlux}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/receipt/productsByIdFlux", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postInsertProduct = createAsyncThunk(
  "/product/insert",
  async (
    {
      productData,
      callBack,
    }: {
      productData: any;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/product/insert", "POST", productData);
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/product/insert", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getInvoiceDetails = createAsyncThunk(
  "/invoice",
  async (
    params: {
      providerId: number | string;
      fluxId: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        `facturiByIdFurnizor/${params.providerId}?idFlux=${params.fluxId}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/invoice", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postInsertInvoice = createAsyncThunk(
  "/insertInvoice",
  async (
    params: {
      finalize?: boolean;
      idFluxReceptie: any;
      facturi: InvoiceDetailsItem[];
    },
    { rejectWithValue, dispatch },
  ) => {
    const saveType = params.finalize ? 1 : 0;
    try {
      const { data } = await apiRequest(
        `insertFacturiWms?requestBy=${saveType}`,
        "POST",
        params,
      );

      handleSetGlobalToast(
        dispatch,
        "Facturile au fost salvate cu succes!",
        "success",
      );
      return data;
    } catch (err: any) {
      console.warn("/insertInvoice", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getNrcdDetails = createAsyncThunk(
  "/receipt/nrcd",
  async (fluxId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/nrcd/${fluxId}`, "GET");
      return data;
    } catch (err: any) {
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const addProductToQarantine = createAsyncThunk(
  "/receipt/addProdusCarantina",
  async (
    nrcdToQuarantine: { data: NrcdDetailsListInterface; callBack: () => void },
    { rejectWithValue, dispatch },
  ) => {
    const { data, callBack } = nrcdToQuarantine;
    try {
      const { data: responseData } = await apiRequest(
        `/addProdusCarantina`,
        "POST",
        data,
      );
      dispatch(getQuarantineDetails(data.idFluxReceptie));
      callBack && callBack();
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/addProdusCarantina", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPreviewQuarantineDetails = createAsyncThunk(
  "/receipt/carantinatePreview",
  async (fluxId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/carantinatePreview/${fluxId}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/receipt/carantinatePreview", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getValidateReception = createAsyncThunk(
  "/receipt/verificaStatus",
  async (
    {
      fluxId,
      callback,
    }: {
      fluxId: number | string;
      callback?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        `/verificaStatus/${fluxId}?requestBy=3`,
        "GET",
      );
      callback && callback();
      return data;
    } catch (err: any) {
      console.warn("/receipt/verificaStatus", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getQuarantineDetails = createAsyncThunk(
  "/receipt/quarantine",
  async (fluxId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/carantinate/${fluxId}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/receipt/quarantine", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const insertQuarantineSolution = createAsyncThunk(
  "/receipt/insertQuarantineSolution",
  async (
    quarantineSolution: { data: any; callBack: () => void },
    { rejectWithValue, dispatch },
  ) => {
    const { data, callBack } = quarantineSolution;
    try {
      const { data: responseData } = await apiRequest(
        `/solutionareCarantina`,
        "POST",
        data,
      );
      dispatch(getQuarantineDetails(data.idFluxReceptie));
      callBack && callBack();
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/insertQuarantineSolution", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postProductUpdate = createAsyncThunk(
  "/receipt/productUpdate",
  async (
    productDetails: {
      productData: any;
      callBack: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { productData, callBack } = productDetails;
    try {
      const { data: responseData } = await apiRequest(
        `/productUpdate`,
        "POST",
        productData,
      );
      callBack && callBack();
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/productUpdate", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getRenumberProducts = createAsyncThunk(
  "/receipt/getRenumberProducts",
  async (
    receptionData: {
      idFluxReceptie: any;
      callBack: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    handleSetGlobalLoading(
      dispatch,
      true,
      "Se incepe renumararea",
      "Acest proces poate dura cateva minute",
    );
    const { idFluxReceptie, callBack } = receptionData;
    try {
      await apiRequest(`/deblocheazaReceptie/${idFluxReceptie}?type=1`);
      callBack && callBack();
      handleSetGlobalLoading(dispatch, false);

      // return responseData;
    } catch (err: any) {
      handleSetGlobalLoading(dispatch, false);
      console.warn("/receipt/getRenumberProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getUnlockInvoices = createAsyncThunk(
  "/receipt/getUnlockInvoices",
  async (
    receptionData: {
      idFluxReceptie: any;
      callBack: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    handleSetGlobalLoading(
      dispatch,
      true,
      "Se incepe renumararea",
      "Acest proces poate dura cateva minute",
    );
    const { idFluxReceptie, callBack } = receptionData;
    try {
      await apiRequest(`/deblocheazaReceptie/${idFluxReceptie}?type=2`);
      callBack && callBack();
      handleSetGlobalLoading(dispatch, false);

      // return responseData;
    } catch (err: any) {
      handleSetGlobalLoading(dispatch, false);
      console.warn("/receipt/getUnlockInvoices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReceptionInvoices = createAsyncThunk(
  "/receipt/getReceptionInvoices",
  async (
    receptionData: {
      idFluxReceptie: any;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idFluxReceptie, callBack } = receptionData;
    try {
      const { data: responseData } = await apiRequest(
        `/editFacturiReceptie/${idFluxReceptie}`,
      );
      callBack && callBack();
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/getReceptionInvoices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUpdateReceptionInvoices = createAsyncThunk(
  "/receipt/postUpdateReceptionInvoices",
  async (
    receptionData: {
      idStatusReceptie: number | string;
      invoices: any[];
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idStatusReceptie, invoices, callBack } = receptionData;

    try {
      await apiRequest(`/updateFacturiReceptie`, "POST", {
        idStatusReceptie,
        invoices,
      });
      callBack && callBack();
      // return responseData;
    } catch (err: any) {
      console.warn("/receipt/postUpdateReceptionInvoices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getTransferTasks = createAsyncThunk(
  "/receipt/showTransferTasksWeb",
  async (
    params: {
      page: number;
      perPage: number;
      status: string;
      type: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, status, type } = params;
      const { data: responseData } = await apiRequest(
        `/showTasksWebTransfer/?page=${page}&per_page=${perPage}&status=${status}&type=${type}`,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/showTransferTasksWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

// start transfer received

export const getTransferReceivedTasks = createAsyncThunk(
  "/receipt/transferReceivedTasks",
  async (
    filters: {
      page?: number;
      per_page?: number;
      searchFurnizor?: string;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { callBack } = filters;

    try {
      const { data: responseData } = await apiRequest(`/receptiiTask`, "GET", {
        ...filters,
        searchFurnizor: filters.searchFurnizor ?? "",
      });
      callBack && callBack();
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/transferReceivedTasks", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getTransferReceivedOperations = createAsyncThunk(
  "/receipt/transferReceivedOperations",
  async (
    receptionData: {
      idFluxReceptie: any;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idFluxReceptie, callBack } = receptionData;
    try {
      const { data: responseData } = await apiRequest(
        `/showTasks/${idFluxReceptie}`,
      );
      callBack && callBack();
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/transferReceivedOperations", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postMultipleTransferReceivedOperations = createAsyncThunk(
  "/receipt/multipleTransferReceivedOperations",
  async (
    receptionData: {
      idFluxReceptie: string | number;
      selectedItems: any[];
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idFluxReceptie, selectedItems, callBack } = receptionData;
    try {
      const transferObject = {
        type: "1",
        idFluxReceptie: idFluxReceptie,
        selectedItems: selectedItems,
      };
      await apiRequest(`/valideazaTasks`, "POST", transferObject);
      dispatch(
        getTransferReceivedOperations({ idFluxReceptie: idFluxReceptie }),
      );
      callBack && callBack();
    } catch (err: any) {
      console.warn("/receipt/multipleTransferReceivedOperations", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postSplitTransferReceivedOperations = createAsyncThunk(
  "/receipt/splitTransferReceivedOperations",
  async (
    receptionData: {
      idFluxReceptie: string | number;
      deposits: any[];
      product: object;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idFluxReceptie, deposits, product, callBack } = receptionData;
    try {
      const transferObject = {
        type: "2",
        idFluxReceptie: idFluxReceptie,
        deposits,
        product,
      };
      await apiRequest(`/valideazaTasks`, "POST", transferObject);
      dispatch(
        getTransferReceivedOperations({ idFluxReceptie: idFluxReceptie }),
      );
      callBack && callBack();
    } catch (err: any) {
      console.warn("/receipt/splitTransferReceivedOperations", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

// end transfer received

// start monitor quarantine
export const getMonitorQuarantine = createAsyncThunk(
  "/receipt/getMonitorQuarantine",
  async (
    filters: {
      page?: number;
      perPage?: number;
      codProdus?: string;
      numeProdus?: string;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, codProdus, numeProdus, callBack } = filters;

    try {
      const { data: responseData } = await apiRequest(
        `/listaCarantinate?page=${page ?? 1}&per_page=${
          perPage ?? 15
        }&codProdus=${codProdus ?? ""}&numeProdus=${numeProdus ?? ""}`,
      );
      callBack && callBack();
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/getMonitorQuarantine", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
// end monitor quarantine

// start delete product
export const getDeleteProduct = createAsyncThunk(
  "/receipt/getDeleteProduct",
  async (
    params: {
      idProdusReceptie: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idProdusReceptie } = params;

    try {
      const { data: responseData } = await apiRequest(
        `/stergeProdusReceptie/${idProdusReceptie}`,
      );
      dispatch(getReceiptsDataList(idProdusReceptie));
      return responseData;
    } catch (err: any) {
      console.warn("/receipt/getDeleteProduct", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
// end monitor quarantine

export const getDownloadNrcd = createAsyncThunk(
  "/expedition/getDownloadNrcd",
  async (
    {
      nrcdId,
      furnizor,
      data,
    }: {
      nrcdId: number;
      furnizor: string;
      data: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(
          `${apiEnv}/carantinate/${nrcdId}?dld=1&furnizor=${furnizor}&data=${data}`,
          "_blank",
        )
        ?.focus();
    } catch (err: any) {
      console.warn("/expedition/getDownloadNrcd", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchProductsWithoutSN = createAsyncThunk(
  "/receipt/getProductsWithoutSN",
  async (
    params: {
      page: number;
      perPage: number;
      numeProdus?: string;
      codProdus?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/getProductsWithoutSN", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/receipt/getProductsWithoutSN", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchCreateOrUpdateProductWithCustomSN = createAsyncThunk(
  "/receipt/fetchCreateOrUpdateProductWithCustomSN",
  async (
    params: {
      id?: number | null;
      idProdusErp: number | null;
      numeProdus: string;
      codProdus: string;
      codGrupa: string;
      numeGrupa: string;
      sn: string;
      tip: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/createOrUpdateProductWithCustomSN",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/receipt/fetchCreateOrUpdateProductWithCustomSN", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchProductsWithCustomSN = createAsyncThunk(
  "/receipt/getProductsWithCustomSN",
  async (
    params: {
      page: number;
      perPage: number;
      numeProdus?: string;
      codProdus?: string;
      codGrupa?: string;
      numeGrupa?: string;
      sn?: string;
      tip?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/getProductsWithCustomSN",
        "GET",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/receipt/getProductsWithCustomSN", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchToggleValidateProductWithCustomSN = createAsyncThunk(
  "/receipt/toggleValidateProductWithCustomSN",
  async (
    params: {
      id: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/toggleValidateProductWithCustomSN",
        "POST",
        params,
      );
      dispatch(toggleProductWithCustomSnValidation(params.id));

      return data;
    } catch (err: any) {
      console.warn("/receipt/toggleValidateProductWithCustomSN", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const deleteProductWithCustomSN = createAsyncThunk(
  "/receipt/deleteProductWithCustomSN",
  async (
    params: {
      id: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/deleteProductWithCustomSN",
        "POST",
        params,
      );
      dispatch(fetchProductsWithCustomSN({ page: 1, perPage: 15 }));
      return data;
    } catch (err: any) {
      console.warn("/receipt/deleteProductWithCustomSN", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchPrintProductSerialNumber = createAsyncThunk(
  "/receipt/fetchPrintProductSerialNumber",
  async (
    params: {
      id: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(`${apiEnv}/printProductSerialNumber?id=${params.id}`, "_blank")
        ?.focus();
    } catch (err: any) {
      console.warn("/receipt/fetchPrintProductSerialNumber", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
