import { useMemo } from "react";
import { useAppSelector } from "../redux/hooks";
import {
  adminRoutes,
  avizPickingRoutes,
  expeditionRoutes,
  inventoryRoutes,
  pickingRoutes,
  putawayRoutes,
  receptionRoutes,
  reportsRoutes,
} from "./routesConstants";

import userRoles from "../constants/userRoles";

interface DashboardCategories {
  color: string;
  title: string;
  subtitle?: string;
  subtitleValue?: string;
  categories: {
    id: string;
    title: string;
    path: string;
    subtitle?: string;
    value?: string | number;
    isIncreasing?: boolean;
  }[];
  navigationLinks?: {
    link: string;
    label: string;
  }[];
}

function useAppMenuRoutes() {
  const {
    user: { userRoutesAccess },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  const receptionMenuRoutes = [
    {
      id: userRoles.RECEPTION,
      title: "Centralizator Receptii",
      path: `/${receptionRoutes.reception}?stadiu=2`,
    },
    {
      id: userRoles.TRANSFER_RECEIVED,
      title: "Transfer marfa receptionata in depozit",
      path: `/${receptionRoutes.reception}/${receptionRoutes.transferReceived}`,
    },
    {
      id: userRoles.MONITOR_QUARANTINE,
      title: "Produse aflate in carantina",
      path: `/${receptionRoutes.reception}/${receptionRoutes.quarantineMonitor}`,
    },
    {
      id: userRoles.TRANSFER_TASKS,
      title: "Monitorizare task-uri de transfer marfa",
      path: `/${receptionRoutes.reception}/${receptionRoutes.transferTasks}`,
    },
    {
      id: userRoles.RETURNS,
      title: "Retururi",
      path: `/${receptionRoutes.returns}`,
    },
    {
      id: userRoles.PRODUCTS_WITH_CUSTOM_BC,
      title: "Produse cu cod de bare custom",
      path: `/${receptionRoutes.productsWithCustomBc}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const putawayMenuRoutes = [
    {
      id: userRoles.PUTAWAY_TASKS,
      title: "Task-uri putaway",
      path: `/${putawayRoutes.putaway}`,
    },
    {
      id: userRoles.PUTAWAY_REFILL_TASKS,
      title: "Task-uri umplere marfa",
      path: `/${putawayRoutes.putaway}/${putawayRoutes.putawayRefill}`,
    },
    {
      id: userRoles.PUTAWAY_REARRANGEMENT_TASKS,
      title: "Task-uri rearanjare marfa",
      path: `/${putawayRoutes.putaway}/${putawayRoutes.putawayRearrangement}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const pickingMenuRoutes = [
    {
      id: userRoles.DELIVERY_ARRANGEMENT,
      title: "Dispozitie livrare",
      path: `/${pickingRoutes.picking}/${pickingRoutes.deliveryArrangement}`,
    },

    {
      id: userRoles.VERIFY_COMMAND,
      title: "Verificare comanda",
      path: `/${pickingRoutes.picking}/${pickingRoutes.verifyCommand}`,
    },
    {
      id: userRoles.PRINT_COMMAND_INVOICES,
      title: "Printeaza comanda",
      path: `/${pickingRoutes.picking}/${pickingRoutes.printCommandInvoice}`,
    },
    {
      id: userRoles.VERIFICATION_ZONES,
      title: "Zone de verificare",
      path: `/${pickingRoutes.picking}/${pickingRoutes.verificationZones}`,
    },
    {
      id: userRoles.DELIVERY_ARRANGEMENT,
      title: "Istoric comanda",
      path: `/${pickingRoutes.picking}/${pickingRoutes.commandHistory}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const avizPickingMenuRoutes = [
    {
      id: userRoles.AVIZ_DELIVERY_ARRANGEMENT,
      title: "Dispozitie livrare cu aviz",
      path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizDeliveryArrangement}`,
    },
    {
      id: userRoles.AVIZ_VERIFY_COMMAND,
      title: "Verificare comanda cu aviz",
      path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizVerifyCommand}`,
    },
    {
      id: userRoles.AVIZ_PRINT_COMMAND_INVOICES,
      title: "Printeaza comanda cu aviz",
      path: `/${avizPickingRoutes.avizPicking}/${avizPickingRoutes.avizPrintCommandInvoice}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const expeditionMenuRoutes = [
    {
      id: userRoles.SCHEDULE_WORKING_POINTS,
      title: "Program puncte de lucru",
      path: `/${expeditionRoutes.scheduleWorkingPoints}`,
    },
    {
      id: userRoles.SCHEDULE_WORKING_ROUTE,
      title: "Program rute de transport",
      path: `/${expeditionRoutes.scheduleWorkingRoute}`,
    },
    {
      id: userRoles.NODES,
      title: "Noduri",
      path: `/${expeditionRoutes.nodes}`,
    },
    {
      id: userRoles.INCOMPLETE_INVOICES,
      title: "Facturi fara destinatar",
      path: `/${expeditionRoutes.incompleteInvoices}`,
    },
    {
      id: userRoles.SCAN_PLASTIC_BOXES,
      title: "Scanare cutii plastic",
      path: `/${expeditionRoutes.scanPlasticBoxes}`,
    },
    {
      id: userRoles.MONITORING_PLASTIC_BOXES,
      title: "Monitorizare cutii plastic",
      path: `/${expeditionRoutes.monitoringPlasticBoxes}`,
    },
    {
      id: userRoles.BORDEROU_HISTORY,
      title: "Istoric borderou",
      path: `/${expeditionRoutes.borderouHistory}`,
    },
    {
      id: userRoles.SEARCH_PACKAGE_BY_OP,
      title: "Cautare colet dupa OP",
      path: `/${expeditionRoutes.expedition}/${expeditionRoutes.searchPackageByOp}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const stocksMenuRoutes = [
    {
      id: userRoles.INVENTORY_RECEIVED_PRODUCTS,
      title: "Intrari produse",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.receivedProducts}`,
    },
    {
      id: userRoles.STOCKS,
      title: "Stocuri",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.depositStocks}`,
    },
    {
      id: userRoles.NOMENCLATURE,
      title: "Nomenclator",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}`,
    },
    {
      id: userRoles.PRODUCTS_THAT_EXPIRE_SOON,
      title: "Produse care expira curand",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.productsThatExpireSoon}`,
    },
    {
      id: userRoles.PRODUCTS_DESTRUCTION_PROCEDURE,
      title: "Produse carantinate, eligibile pentru distrugere",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.productsDestructionProcedure}`,
    },
    {
      id: userRoles.DUPLICATE_PRODUCTS,
      title: "Produse duplicat",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.duplicateProducts}`,
    },
    {
      id: userRoles.RETURNING_PRODUCTS_TO_PROVIDER,
      title: "Produse carantinate, eligibile pentru returnare catre furnizor",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.returningProductsToProvider}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const reportsMenuRoutes = [
    {
      id: userRoles.USERS_ACTIVITY,
      title: "Activitate utilizatori",
      path: `/${reportsRoutes.reports}/${reportsRoutes.usersActivity}`,
    },
    {
      id: userRoles.PRODUCTS_DESTRUCTION_PROCEDURE,
      title: "Rapoarte produse distruse",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.productsDestroyedRaports}`,
    },
    {
      id: userRoles.RAPORTS,
      title: "Rapoarte",
      path: `/${receptionRoutes.reception}/${receptionRoutes.raports}`,
    },
    {
      id: userRoles.USERS_EXTRA_ACTIVITIES,
      title: "Extra activitati utilizatori",
      path: `/${reportsRoutes.reports}/${reportsRoutes.usersExtraActivities}`,
    },
    {
      id: userRoles.QUARANTINE_PRODUCT_RECORDS,
      title: "Evidenta produselor aflate in carantina",
      path: `/${reportsRoutes.reports}/${reportsRoutes.quarantineProductRecords}`,
    },
    {
      id: userRoles.RETURNING_PRODUCTS_TO_PROVIDER,
      title: "Rapoarte returnare produse catre furnizor",
      path: `/${reportsRoutes.reports}/${reportsRoutes.returningProductsToProviderReports}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const adminMenuRoutes = [
    {
      id: userRoles.PREVIEW_USERS,
      title: "Utilizatori",
      path: `/${adminRoutes.users}`,
    },
    {
      id: userRoles.CREATE_USER,
      title: "Creeaza utilizator",
      path: `/${adminRoutes.users}/${adminRoutes.createUser}`,
    },
    {
      id: userRoles.CELLS_NOMENCLATURE,
      title: "Nomenclator celule",
      path: `/${adminRoutes.cellsNomenclature}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const categories: DashboardCategories[] = useMemo(
    () =>
      [
        {
          color: "#3699FF",
          title: "Receptie",
          categories: receptionMenuRoutes,
        },
        {
          color: "#3699FF",
          title: "Putaway",
          categories: putawayMenuRoutes,
        },
        {
          color: "#3699FF",
          title: "Picking",
          categories: pickingMenuRoutes,
        },
        {
          color: "#3636FF",
          title: "Picking cu aviz",
          categories: avizPickingMenuRoutes,
        },
        {
          color: "#3699FF",
          title: "Expeditie",
          categories: expeditionMenuRoutes,
        },
        {
          color: "#3699FF",
          title: "Stocuri",
          categories: stocksMenuRoutes,
        },
        { color: "#3699FF", title: "Rapoarte", categories: reportsMenuRoutes },
        {
          color: "#3699FF",
          title: "Administrare",
          categories: adminMenuRoutes,
        },
      ].filter((category) => category.categories.length > 0),
    [
      receptionMenuRoutes,
      putawayMenuRoutes,
      avizPickingMenuRoutes,
      pickingMenuRoutes,
      expeditionMenuRoutes,
      stocksMenuRoutes,
      reportsMenuRoutes,
      adminMenuRoutes,
    ],
  );

  return categories;
}

export default useAppMenuRoutes;
