import React from "react";
import Text from "../../styleguide/Text";
import SelectableChecker from "./SelectableItem/SelectableChecker";

function Checkbox({
  name,
  label,
  labelAlign = "right",
  disabled,
  checked,
  onChange,
  textVariant,
  className,
  CustomLabelComponent,
  withFullWidth = true,
}: {
  name?: string;
  label?: string;
  labelAlign?: "left" | "right";
  disabled?: boolean;
  checked?: boolean;
  onChange?: (isChecked: boolean) => void;
  textVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2";
  className?: string;
  CustomLabelComponent?: React.ReactNode;
  withFullWidth?: boolean;
}) {
  const handleChange = (isChecked: boolean) => {
    onChange && onChange(isChecked);
  };

  const getCustomLabelComponent = () => {
    if (CustomLabelComponent) {
      return CustomLabelComponent;
    }
    return (
      <Text
        forId={name}
        marginRight={1}
        component="label"
        className={`${name ? "cursor-pointer" : ""} ms-2`}
        variant={textVariant}>
        {label}
      </Text>
    );
  };

  return (
    <div
      className={`d-flex align-items-center ${
        withFullWidth ? "justify-content-between" : ""
      }  ${className}`}>
      {label && labelAlign === "left" && getCustomLabelComponent()}
      <SelectableChecker
        type="checkbox"
        id={name}
        name={name ?? "checkbox"}
        checked={checked}
        onChange={handleChange}
        isDisabled={disabled}
      />

      {label && labelAlign === "right" && getCustomLabelComponent()}
    </div>
  );
}

export default Checkbox;
