import { useAppDispatch } from "../../../../redux/hooks";

import { CellsNomenclatureListInterface } from "../../../../redux/interfaces/admin.interface";
import CustomButton from "../../../../components/CustomButton";
import { adminRoutes } from "../../../../router/routesConstants";
import {
  fetchDeleteCellNomenclature,
  getPrintCellNomenclature,
} from "../../../../redux/api/admin.api";

function CellsNomenclatureActionButton({
  itemId,
  crudData,
}: {
  itemId: string;
  crudData: CellsNomenclatureListInterface;
}) {
  const dispatch = useAppDispatch();

  const { idCelula } = crudData;

  const handleDeleteCellNomenclature = () => {
    dispatch(
      fetchDeleteCellNomenclature({
        idCelula: idCelula,
      }),
    );
  };

  const goToUpdateCellNomenclature = () => {
    if (idCelula) {
      return `${itemId}/${adminRoutes.editCellNomenclature}?idCelula=${idCelula}`;
    }
  };

  const handleDownloadBarCodeOfCell = () => {
    dispatch(
      getPrintCellNomenclature({
        idCelula: idCelula,
      }),
    );
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        fullWidth
        navigate={goToUpdateCellNomenclature()}>
        Editeaza
      </CustomButton>

      <CustomButton
        variant="contained"
        className="me-1 mt-1"
        fullWidth
        withConfirmationModal={{
          modalTitle: `Doriti sa tipariti codul de bare pentru celula curenta?`,
          modalLeftButtonVariant: "outlined",
          modalRightButtonOnClick: handleDownloadBarCodeOfCell,
        }}>
        Printeaza
      </CustomButton>

      <CustomButton
        variant="contained"
        color="error"
        className="mt-1 me-1"
        fullWidth
        withConfirmationModal={{
          modalLeftButtonVariant: "outlined",
          modalTitle: `Doriti sa stergeti celula ?`,
          modalRightButtonOnClick: handleDeleteCellNomenclature,
        }}>
        Sterge
      </CustomButton>
    </div>
  );
}

export default CellsNomenclatureActionButton;
