import { createSlice } from "@reduxjs/toolkit";
import {
  getNomenclature,
  postStartReceipt,
  getReceipts,
  getProductDetailsById,
  getReceiptsDataList,
  postInsertProduct,
  getInvoiceDetails,
  postInsertInvoice,
  getNrcdDetails,
  addProductToQarantine,
  getQuarantineDetails,
  insertQuarantineSolution,
  postProductUpdate,
  getPreviewQuarantineDetails,
  getReceptionInvoices,
  getValidateReception,
  getTransferReceivedTasks,
  getTransferReceivedOperations,
  getTransferTasks,
  getMonitorQuarantine,
  fetchProductsWithoutSN,
  fetchProductsWithCustomSN,
} from "../api/receipt.api";

import {
  InsertProductToQarantine,
  AvaibleReceiptsListInterface,
  CreateReceptionInterface,
  InsertInvoice,
  InsertProduct,
  InvoiceDetails,
  NomenclatureInterface,
  NrcdDetailsInterface,
  ProductInterface,
  ReceiptDetailsInterface,
  QuarantineDetailsInterface,
  InsertQuarantineSolution,
  ProductUpdateInterface,
  PreviewQuarantineDetailsInterface,
  EditReceptionInvoicesInterface,
  PostValidateReceiptInterface,
  TransferReceivedInterface,
  TransferReceivedOperationsInterface,
  TransferTasksInterface,
  MonitorQuarantineInterface,
  ProductsWithoutSNInterface,
  ProductsWithCustomSnInterface,
  ProductsWithCustomSnListInterface,
} from "../interfaces/receipt.interface";

export const defaultStateReceipt: {
  globalErrorMessage: string;
  nomenclature: NomenclatureInterface;
  createReception: CreateReceptionInterface;
  fluxCreatedData: null | {
    id: number;
    idFluxReceptie: number;
    idFurnizor: string;
    idZonaReceptie: string;
    statusZona: number;
    userId: number;
  };
  avaibleReceiptsList: AvaibleReceiptsListInterface;
  receiptDetails: ReceiptDetailsInterface;
  productDetails: ProductInterface;
  insertProduct: InsertProduct;
  invoiceDetails: InvoiceDetails;
  insertInvoice: InsertInvoice;
  nrcdDetails: NrcdDetailsInterface;
  insertProductToQarantine: InsertProductToQarantine;
  previewQuarantineDetails: PreviewQuarantineDetailsInterface;
  postValidateReceipt: PostValidateReceiptInterface;
  quarantineDetails: QuarantineDetailsInterface;
  insertQuarantineSolution: InsertQuarantineSolution;
  productUpdate: ProductUpdateInterface;
  editReceptionInvoices: EditReceptionInvoicesInterface;
  transferReceived: TransferReceivedInterface;
  transferReceivedOperations: TransferReceivedOperationsInterface;
  transferTasks: TransferTasksInterface;
  monitorQuarantine: MonitorQuarantineInterface;
  productsWithoutSN: ProductsWithoutSNInterface;
  productsWithCustomBc: ProductsWithCustomSnInterface;
  createProductWithCustomSn: ProductsWithCustomSnListInterface | null;
} = {
  globalErrorMessage: "",
  nomenclature: {
    zone: null,
    provider: null,
    isLoadingNomenclature: false,
    isErrorNomenclature: false,
    errorMessageNomenclature: "",
  },
  createReception: {
    idFurnizor: null,
    fluxReceptie: null,
    idZonaReceptie: null,
    isLoadingCreateReception: false,
    errorMessageCreateReception: "",
    receptionCreated: false,
  },
  fluxCreatedData: null,
  avaibleReceiptsList: {
    data: {
      nrTotalReceptii: 1,
      nrPagini: 1,
      receptii: [],
    },
    isLoadingReceipts: true,
    errorMessageReceiptsList: "",
  },
  productDetails: {
    data: null,
    isLoadingProduct: true,
    isErrorProduct: false,
    errorMessageProduct: "",
  },
  receiptDetails: {
    data: {
      furnizor: null,
      products: [],
      status: null,
    },
    isLoadingReceiptData: true,
    isErrorReceiptData: false,
    errorMessageReceiptData: "",
  },
  insertProduct: {
    isLoadingInsertProduct: false,
    isErrorInsertProduct: false,
    errorMessageInsertProduct: "",
  },
  invoiceDetails: {
    data: null,
    isLoadingInvoiceDetailsData: true,
    isErrorInvoiceDetailsData: false,
    errorMessageInvoiceDetailsData: "",
  },
  insertInvoice: {
    isLoadingInsertInvoice: false,
    isErrorInsertInvoice: false,
    errorMessageInsertInvoice: "",
  },
  nrcdDetails: {
    data: {
      furnizor: null,
      nrcdList: [],
      startNrcd: null,
    },
    isLoadingNrcdDetails: true,
    errorMessageNrcdDetails: "",
    isErrorNrcdDetails: false,
  },
  insertProductToQarantine: {
    isLoadingAddProductToQarantine: false,
    errorMessageAddProductToQarantine: "",
    isErrorAddProductToQarantine: false,
  },
  previewQuarantineDetails: {
    data: {
      furnizor: null,
      quarantineList: [],
      startQuarantine: null,
    },
    isLoadingPreviewQuarantineDetails: true,
    errorMessagePreviewQuarantineDetails: "",
    isErrorPreviewQuarantineDetails: false,
  },
  quarantineDetails: {
    data: {
      furnizor: null,
      quarantineList: [],
      startQuarantine: null,
    },
    isLoadingQuarantineDetails: true,
    errorMessageQuarantineDetails: "",
    isErrorQuarantineDetails: false,
  },
  postValidateReceipt: {
    isLoadingValidateReceipt: false,
    isErrorValidateReceipt: false,
    errorMessageValidateReceipt: "",
  },
  insertQuarantineSolution: {
    errorMessageQuarantineSolution: "",
    isErrorQuarantineSolution: false,
    isLoadingQuarantineSolution: false,
  },
  productUpdate: {
    isLoadingProductUpdate: false,
    isErrorProductUpdate: false,
    errorMessageProductUpdate: "",
  },
  editReceptionInvoices: {
    data: null,
    isLoadingEditReceptionInvoices: true,
    isErrorEditReceptionInvoices: false,
    errorMessageEditReceptionInvoices: "",
  },
  transferReceived: {
    data: {
      nrTotalReceptii: 1,
      nrPagini: 1,
      receptii: [],
    },
    isLoadingTransferReceived: true,
    isErrorTransferReceived: false,
    errorMessageTransferReceived: "",
  },
  transferReceivedOperations: {
    data: null,
    isLoadingTransferReceivedOperations: true,
    isErrorTransferReceivedOperations: false,
    errorMessageTransferReceivedOperations: "",
  },
  transferTasks: {
    data: {
      nrTotalTaskuriTransfer: 1,
      nrPagini: 1,
      taskuriTransfer: [],
    },
    isLoadingTransferTasks: true,
    isErrorTransferTasks: false,
    errorMessageTransferTasks: "",
  },
  monitorQuarantine: {
    data: {
      nrTotalTaskuriMonitorizare: 1,
      nrPagini: 1,
      taskuriMonitorizare: [],
    },
    isLoadingMonitorQuarantine: true,
    isErrorMonitorQuarantine: false,
    errorMessageMonitorQuarantine: "",
  },
  productsWithoutSN: {
    data: {
      total: 1,
      data: [],
    },
    isLoading: true,
    isError: false,
    errorMessage: "",
  },
  productsWithCustomBc: {
    data: {
      total: 1,
      data: [],
    },
    isLoading: true,
    isError: false,
    errorMessage: "",
  },
  createProductWithCustomSn: null,
};

const receipt = createSlice({
  name: "receipt",
  initialState: defaultStateReceipt,
  reducers: {
    // nomenclatoare
    removeNomenclator(state) {
      return {
        ...state,
        nomenclature: {
          ...defaultStateReceipt.nomenclature,
          isLoadingNomenclature: false,
        },
      };
    },
    // start reception
    removeCreateReception(state) {
      return {
        ...state,
        createReception: {
          ...defaultStateReceipt.createReception,
          isLoadingCreateReception: false,
        },
      };
    },
    removeFluxCreated(state) {
      return {
        ...state,
        fluxCreatedData: null,
      };
    },
    clearAvailableReceiptsList(state) {
      return {
        ...state,
        avaibleReceiptsList: defaultStateReceipt.avaibleReceiptsList,
      };
    },
    clearProductDetails(state) {
      return {
        ...state,
        productDetails: defaultStateReceipt.productDetails,
      };
    },
    clearInsertProduct(state) {
      return {
        ...state,
        insertProduct: defaultStateReceipt.insertProduct,
      };
    },
    clearNrcdDetails(state) {
      return {
        ...state,
        nrcdDetails: defaultStateReceipt.nrcdDetails,
      };
    },
    clearQuarantineDetails(state) {
      return {
        ...state,
        quarantineDetails: defaultStateReceipt.quarantineDetails,
      };
    },
    clearPreviewQuarantineDetails(state) {
      return {
        ...state,
        previewQuarantineDetails: defaultStateReceipt.previewQuarantineDetails,
      };
    },
    clearInvoiceDetails(state) {
      return {
        ...state,
        invoiceDetails: defaultStateReceipt.invoiceDetails,
      };
    },
    clearMonitorQuarantine(state) {
      return {
        ...state,
        monitorQuarantine: defaultStateReceipt.monitorQuarantine,
      };
    },
    clearProductsWithoutSN(state) {
      return {
        ...state,
        productsWithoutSN: defaultStateReceipt.productsWithoutSN,
      };
    },
    toggleProductWithCustomSnValidation(state, action) {
      const toggledProductsWithCustomSn =
        state.productsWithCustomBc.data.data?.map((product) => {
          if (product.id === action.payload.id) {
            return {
              ...product,
              validated: !product.validated,
            };
          }
          return product;
        });
      return {
        ...state,
        productsWithCustomBc: {
          ...state.productsWithCustomBc,
          data: {
            ...state.productsWithCustomBc.data,
            data: toggledProductsWithCustomSn,
          },
        },
      };
    },
    clearProductsWithCustomSn(state) {
      return {
        ...state,
        ProductsWithCustomSnInterface: defaultStateReceipt.productsWithCustomBc,
      };
    },
    setProductWithCustomBc(state, action) {
      return {
        ...state,
        createProductWithCustomSn: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // nomenclature
    builder.addCase(getNomenclature.pending, (state) => {
      return {
        ...state,
        nomenclature: {
          ...defaultStateReceipt.nomenclature,
          isLoadingNomenclature: true,
        },
      };
    });
    builder.addCase(getNomenclature.rejected, (state, action: any) => {
      return {
        ...state,
        nomenclature: {
          ...state.nomenclature,
          isLoadingNomenclature: false,
          errorMessageNomenclature: action.payload.message,
          isErrorNomenclature: true,
        },
      };
    });
    builder.addCase(getNomenclature.fulfilled, (state, action) => {
      return {
        ...state,
        nomenclature: {
          ...state.nomenclature,
          isLoadingNomenclature: false,
          zone: action.payload.zone,
          provider: action.payload.furnizor,
        },
      };
    });
    // start receipt
    builder.addCase(postStartReceipt.pending, (state) => {
      return {
        ...state,
        createReception: {
          ...defaultStateReceipt.createReception,
          isLoadingCreateReception: true,
        },
      };
    });
    builder.addCase(postStartReceipt.rejected, (state, action: any) => {
      return {
        ...state,
        createReception: {
          ...defaultStateReceipt.createReception,
          isLoadingCreateReception: false,
          errorMessageCreateReception: action.payload.message,
        },
      };
    });
    builder.addCase(postStartReceipt.fulfilled, (state, action) => {
      return {
        ...state,
        nomenclature: {
          ...defaultStateReceipt.nomenclature,
        },
        createReception: {
          ...defaultStateReceipt.createReception,
          isLoadingCreateReception: false,
          receptionCreated: true,
        },
        fluxCreatedData: action.payload.receptie,
      };
    });
    // get receipts list
    builder.addCase(getReceipts.pending, (state) => {
      return {
        ...state,
        avaibleReceiptsList: {
          ...defaultStateReceipt.avaibleReceiptsList,
          isLoadingReceipts: true,
        },
      };
    });
    builder.addCase(getReceipts.rejected, (state, action: any) => {
      return {
        ...state,
        avaibleReceiptsList: {
          ...defaultStateReceipt.avaibleReceiptsList,
          isLoadingReceipts: true,
          errorMessageReceiptsList: action.payload.message,
        },
      };
    });
    builder.addCase(getReceipts.fulfilled, (state, action) => {
      return {
        ...state,
        avaibleReceiptsList: {
          ...defaultStateReceipt.avaibleReceiptsList,
          isLoadingReceipts: false,
          data: action.payload,
        },
      };
    });
    // get product details
    builder.addCase(getProductDetailsById.pending, (state) => {
      return {
        ...state,
        productDetails: {
          ...defaultStateReceipt.productDetails,
          isLoadingProductDetails: true,
        },
      };
    });
    builder.addCase(getProductDetailsById.rejected, (state, action: any) => {
      return {
        ...state,
        productDetails: {
          ...defaultStateReceipt.productDetails,
          isLoadingProduct: false,
          isErrorProduct: true,
          errorMessageProduct: action.payload.message,
        },
        globalErrorMessage: action.payload.message,
      };
    });
    builder.addCase(getProductDetailsById.fulfilled, (state, action) => {
      const productData: any = action.payload;
      return {
        ...state,
        productDetails: {
          ...defaultStateReceipt.productDetails,
          isLoadingProduct: false,
          isErrorProduct: false,
          errorMessageProduct: "",
          data: {
            id: productData.idProdusErp,
            name: productData.numeProdusErp,
            code: productData.codProdusErp,

            boxWidth: productData.latimeB,
            boxLength: productData.lungimeB,
            boxHeight: productData.inaltimeB,
            boxWeigth: productData.greutateB,
            boxQuantity: productData.cantitateB,

            packWidth: productData.latimeA,
            packHeight: productData.inaltimeA,
            packLength: productData.lungimeA,
            packWeigth: productData.inaltimeA,
            packQuantity: productData.cantitate,

            cantitateDeteriorata: productData.cantitateDeteriorata,
            // comp
            serialNumber: productData.sn,
            expirationDate: productData.dataExpirare,
            lot: productData.lot,

            idFluxReceptie: productData.idFluxReceptie,
            ...productData,
          },
        },
      };
    });
    // get receipts data list
    builder.addCase(getReceiptsDataList.pending, (state) => {
      return {
        ...state,
        receiptDetails: {
          ...defaultStateReceipt.receiptDetails,
        },
      };
    });
    builder.addCase(getReceiptsDataList.rejected, (state, action: any) => {
      return {
        ...state,
        receiptDetails: {
          ...state.receiptDetails,
          isLoadingReceiptData: false,
          isErrorReceiptData: true,
          errorMessageReceiptData: action.payload.message,
        },
      };
    });
    builder.addCase(getReceiptsDataList.fulfilled, (state, action) => {
      return {
        ...state,
        receiptDetails: {
          ...defaultStateReceipt.receiptDetails,
          isLoadingReceiptData: false,
          data: action.payload,
        },
      };
    });
    // post insert product
    builder.addCase(postInsertProduct.pending, (state) => {
      return {
        ...state,
        insertProduct: {
          ...defaultStateReceipt.insertProduct,
          isLoadingInsertProduct: true,
        },
      };
    });
    builder.addCase(postInsertProduct.rejected, (state, action: any) => {
      return {
        ...state,
        insertProduct: {
          ...defaultStateReceipt.insertProduct,
          isLoadingInsertProduct: false,
          isErrorInsertProduct: true,
          errorMessageInsertProduct: action.payload.message,
        },
      };
    });
    builder.addCase(postInsertProduct.fulfilled, (state) => {
      return {
        ...state,
        insertProduct: {
          ...defaultStateReceipt.insertProduct,
          isLoadingInsertProduct: false,
        },
      };
    });
    // get Invoice
    builder.addCase(getInvoiceDetails.pending, (state) => {
      return {
        ...state,
        invoiceDetails: {
          ...defaultStateReceipt.invoiceDetails,
          isLoadingInvoiceDetailsData: true,
        },
      };
    });
    builder.addCase(getInvoiceDetails.rejected, (state, action: any) => {
      return {
        ...state,
        invoiceDetails: {
          ...defaultStateReceipt.invoiceDetails,
          isLoadingInvoiceDetailsData: false,
          isErrorInvoiceDetailsData: true,
          errorMessageInvoiceDetailsData: action.payload.message,
        },
        globalErrorMessage: action.payload.message,
      };
    });
    builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
      return {
        ...state,
        invoiceDetails: {
          ...defaultStateReceipt.invoiceDetails,
          isLoadingInvoiceDetailsData: false,
          isErrorInvoiceDetailsData: false,
          data: action.payload,
        },
      };
    });
    // insert invoice
    builder.addCase(postInsertInvoice.pending, (state) => {
      return {
        ...state,
        insertInvoice: {
          ...defaultStateReceipt.insertInvoice,
          isLoadingInsertInvoice: true,
        },
      };
    });
    builder.addCase(postInsertInvoice.rejected, (state, action: any) => {
      return {
        ...state,
        insertInvoice: {
          ...defaultStateReceipt.insertInvoice,
          isLoadingInsertInvoice: false,
          isErrorInsertInvoice: true,
          errorMessageInsertInvoice: action.payload?.message,
        },
        globalErrorMessage: action.payload?.message,
      };
    });
    builder.addCase(postInsertInvoice.fulfilled, (state) => {
      return {
        ...state,
        insertInvoice: {
          ...defaultStateReceipt.insertInvoice,
          isLoadingInsertInvoice: false,
        },
      };
    });
    // get nrcd
    builder.addCase(getNrcdDetails.pending, (state) => {
      return {
        ...state,
        nrcdDetails: {
          ...defaultStateReceipt.nrcdDetails,
          isLoadingNrcdDetails: true,
        },
      };
    });
    builder.addCase(getNrcdDetails.rejected, (state, action: any) => {
      return {
        ...state,
        nrcdDetails: {
          ...defaultStateReceipt.nrcdDetails,
          isLoadingNrcdDetails: false,
          isErrorNrcdDetails: true,
          errorMessageNrcdDetails: action.payload.message,
        },
      };
    });
    builder.addCase(getNrcdDetails.fulfilled, (state, action: any) => {
      return {
        ...state,
        nrcdDetails: {
          ...defaultStateReceipt.nrcdDetails,
          isLoadingNrcdDetails: false,
          data: action.payload,
        },
      };
    });
    // get nrcd end
    // insert nrcd
    builder.addCase(addProductToQarantine.pending, (state) => {
      return {
        ...state,
        addProductToQarantine: {
          ...defaultStateReceipt.insertProductToQarantine,
          isLoadingAddProductToQarantine: true,
        },
      };
    });
    builder.addCase(addProductToQarantine.rejected, (state, action: any) => {
      return {
        ...state,
        addProductToQarantine: {
          ...defaultStateReceipt.insertProductToQarantine,
          isErrorAddProductToQarantine: true,
          errorMessageAddProductToQarantine: action.payload.message,
        },
        globalErrorMessage: action.payload.message,
      };
    });
    builder.addCase(addProductToQarantine.fulfilled, (state) => {
      return {
        ...state,
        addProductToQarantine: {
          ...defaultStateReceipt.insertProductToQarantine,
        },
      };
    });
    // insert nrcd end
    // get preview quarantine start
    builder.addCase(getPreviewQuarantineDetails.pending, (state) => {
      return {
        ...state,
        previewQuarantineDetails: {
          ...defaultStateReceipt.previewQuarantineDetails,
          isLoadingPreviewQuarantineDetails: true,
        },
      };
    });
    builder.addCase(
      getPreviewQuarantineDetails.rejected,
      (state, action: any) => {
        return {
          ...state,
          previewQuarantineDetails: {
            ...defaultStateReceipt.previewQuarantineDetails,
            isLoadingPreviewQuarantineDetails: false,
            isErrorPreviewQuarantineDetails: true,
            errorMessagePreviewQuarantineDetails: action.payload.message,
          },
          globalErrorMessage: action.payload.message,
        };
      },
    );
    builder.addCase(
      getPreviewQuarantineDetails.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          previewQuarantineDetails: {
            ...defaultStateReceipt.previewQuarantineDetails,
            isLoadingPreviewQuarantineDetails: false,
            data: action.payload,
          },
        };
      },
    );
    // get preview quarantine end
    // get quarantine start
    builder.addCase(getQuarantineDetails.pending, (state) => {
      return {
        ...state,
        quarantineDetails: {
          ...defaultStateReceipt.quarantineDetails,
          isLoadingQuarantineDetails: true,
        },
      };
    });
    builder.addCase(getQuarantineDetails.rejected, (state, action: any) => {
      return {
        ...state,
        quarantineDetails: {
          ...defaultStateReceipt.quarantineDetails,
          isLoadingQuarantineDetails: false,
          isErrorQuarantineDetails: true,
          errorMessageQuarantineDetails: action.payload.message,
        },
        globalErrorMessage: action.payload.message,
      };
    });
    builder.addCase(getQuarantineDetails.fulfilled, (state, action: any) => {
      return {
        ...state,
        quarantineDetails: {
          ...defaultStateReceipt.quarantineDetails,
          isLoadingQuarantineDetails: false,
          data: action.payload,
        },
      };
    });
    // get quarantine end
    // insert quarantine solution start
    builder.addCase(insertQuarantineSolution.pending, (state) => {
      return {
        ...state,
        insertQuarantineSolution: {
          ...defaultStateReceipt.insertQuarantineSolution,
          isLoadingQuarantineSolution: true,
        },
      };
    });
    builder.addCase(insertQuarantineSolution.rejected, (state, action: any) => {
      return {
        ...state,
        insertQuarantineSolution: {
          ...defaultStateReceipt.insertQuarantineSolution,
          isLoadingQuarantineSolution: false,
          isErrorQuarantineSolution: true,
          errorMessageQuarantineSolution: action.payload.message,
        },
        globalErrorMessage: action.payload.message,
      };
    });
    builder.addCase(insertQuarantineSolution.fulfilled, (state) => {
      return {
        ...state,
        insertQuarantineSolution: {
          ...defaultStateReceipt.insertQuarantineSolution,
          isLoadingQuarantineSolution: false,
        },
      };
    });
    // insert quarantine solution end
    // update product start
    builder.addCase(postProductUpdate.pending, (state) => {
      return {
        ...state,
        productUpdate: {
          ...defaultStateReceipt.productUpdate,
          isLoadingUpdateProduct: true,
        },
      };
    });
    builder.addCase(postProductUpdate.rejected, (state, action: any) => {
      return {
        ...state,
        productUpdate: {
          ...defaultStateReceipt.productUpdate,
          isLoadingUpdateProduct: false,
          isErrorUpdateProduct: true,
          errorMessageUpdateProduct: action.payload.message,
        },
      };
    });
    builder.addCase(postProductUpdate.fulfilled, (state) => {
      return {
        ...state,
        productUpdate: {
          ...defaultStateReceipt.productUpdate,
          isLoadingUpdateProduct: false,
        },
      };
    });
    // update product end

    // get receipts invoices start
    builder.addCase(getReceptionInvoices.pending, (state) => {
      return {
        ...state,
        editReceptionInvoices: {
          ...defaultStateReceipt.editReceptionInvoices,
          isLoadingEditReceptionInvoices: true,
        },
      };
    });
    builder.addCase(getReceptionInvoices.rejected, (state, action: any) => {
      return {
        ...state,
        editReceptionInvoices: {
          ...defaultStateReceipt.editReceptionInvoices,
          isLoadingEditReceptionInvoices: false,
          isErrorEditReceptionInvoices: true,
          errorMessageEditReceptionInvoices: action.payload.message,
        },
      };
    });
    builder.addCase(getReceptionInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        editReceptionInvoices: {
          ...defaultStateReceipt.editReceptionInvoices,
          data: action.payload,
          isLoadingEditReceptionInvoices: false,
        },
      };
    });
    // get receipts invoices end

    // post validare reception start
    builder.addCase(getValidateReception.pending, (state) => {
      return {
        ...state,
        postValidateReceipt: {
          isErrorValidateReceipt: false,
          errorMessageValidateReceipt: "",
          isLoadingValidateReceipt: true,
        },
      };
    });
    builder.addCase(getValidateReception.rejected, (state, action: any) => {
      return {
        ...state,
        postValidateReceipt: {
          isLoadingValidateReceipt: false,
          isErrorValidateReceipt: true,
          errorMessageValidateReceipt: action.payload.message,
        },
      };
    });
    builder.addCase(getValidateReception.fulfilled, (state) => {
      return {
        ...state,
        postValidateReceipt: {
          isLoadingValidateReceipt: false,
          isErrorValidateReceipt: false,
          errorMessageValidateReceipt: "",
        },
      };
    });
    // post validare reception end

    // get transfer received start
    builder.addCase(getTransferReceivedTasks.pending, (state) => {
      return {
        ...state,
        transferReceived: {
          ...defaultStateReceipt.transferReceived,
          isLoadingTransferReceived: true,
        },
      };
    });
    builder.addCase(getTransferReceivedTasks.rejected, (state, action: any) => {
      return {
        ...state,
        transferReceived: {
          ...state.transferReceived,
          isLoadingTransferReceived: false,
          errorMessageTransferReceived: action.payload.message,
          isErrorTransferReceived: true,
        },
      };
    });
    builder.addCase(getTransferReceivedTasks.fulfilled, (state, action) => {
      return {
        ...state,
        transferReceived: {
          ...state.transferReceived,
          isLoadingTransferReceived: false,
          data: action.payload,
        },
      };
    });
    // get transfer received end

    // get transfer received operations start
    builder.addCase(getTransferReceivedOperations.pending, (state) => {
      return {
        ...state,
        transferReceivedOperations: {
          ...defaultStateReceipt.transferReceivedOperations,
          isLoadingPutawayProducts: true,
        },
      };
    });
    builder.addCase(
      getTransferReceivedOperations.rejected,
      (state, action: any) => {
        return {
          ...state,
          transferReceivedOperations: {
            ...state.transferReceivedOperations,
            isLoadingTransferReceivedOperations: false,
            errorMessageTransferReceivedOperations: action.payload.message,
            isErrorTransferReceivedOperations: true,
          },
        };
      },
    );
    builder.addCase(
      getTransferReceivedOperations.fulfilled,
      (state, action) => {
        return {
          ...state,
          transferReceivedOperations: {
            ...state.transferReceivedOperations,
            isLoadingTransferReceivedOperations: false,
            data: action.payload,
          },
        };
      },
    );
    // get transfer received operations end
    // get transfer tasks start
    builder.addCase(getTransferTasks.pending, (state) => {
      return {
        ...state,
        transferTasks: {
          ...defaultStateReceipt.transferTasks,
          isLoadingTransferTasks: true,
        },
      };
    });
    builder.addCase(getTransferTasks.rejected, (state, action: any) => {
      return {
        ...state,
        transferTasks: {
          ...state.transferTasks,
          isLoadingTransferTasks: false,
          errorMessageTransferTasks: action.payload.message,
          isErrorTransferTasks: true,
        },
      };
    });
    builder.addCase(getTransferTasks.fulfilled, (state, action) => {
      return {
        ...state,
        transferTasks: {
          ...state.transferTasks,
          isLoadingTransferTasks: false,
          data: action.payload,
        },
      };
    });
    // get transfer tasks end

    // get monitor quarantine start
    builder.addCase(getMonitorQuarantine.pending, (state) => {
      return {
        ...state,
        monitorQuarantine: {
          ...defaultStateReceipt.monitorQuarantine,
          isLoadingMonitorQuarantine: true,
        },
      };
    });
    builder.addCase(getMonitorQuarantine.rejected, (state, action: any) => {
      return {
        ...state,
        monitorQuarantine: {
          ...state.monitorQuarantine,
          isLoadingMonitorQuarantine: false,
          errorMessageMonitorQuarantine: action.payload.message,
          isErrorMonitorQuarantine: true,
        },
      };
    });
    builder.addCase(getMonitorQuarantine.fulfilled, (state, action) => {
      return {
        ...state,
        monitorQuarantine: {
          ...state.monitorQuarantine,
          isLoadingMonitorQuarantine: false,
          data: action.payload,
        },
      };
    });
    // get monitor quarantine end
    // get products without sn start
    builder.addCase(fetchProductsWithoutSN.pending, (state) => {
      return {
        ...state,
        productsWithoutSN: {
          ...defaultStateReceipt.productsWithoutSN,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchProductsWithoutSN.rejected, (state, action: any) => {
      return {
        ...state,
        productsWithoutSN: {
          ...state.productsWithoutSN,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchProductsWithoutSN.fulfilled, (state, action) => {
      return {
        ...state,
        productsWithoutSN: {
          ...state.productsWithoutSN,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get products without sn end
    // get products with custom bc start
    builder.addCase(fetchProductsWithCustomSN.pending, (state) => {
      return {
        ...state,
        productsWithCustomBc: {
          ...defaultStateReceipt.productsWithCustomBc,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      fetchProductsWithCustomSN.rejected,
      (state, action: any) => {
        return {
          ...state,
          productsWithCustomBc: {
            ...state.productsWithCustomBc,
            isLoading: false,
            errorMessage: action.payload.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(fetchProductsWithCustomSN.fulfilled, (state, action) => {
      return {
        ...state,
        productsWithCustomBc: {
          ...state.productsWithCustomBc,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // end products with custom bc start
  },
});

export const {
  removeNomenclator,
  removeCreateReception,
  removeFluxCreated,
  clearAvailableReceiptsList,
  clearProductDetails,
  clearInsertProduct,
  clearNrcdDetails,
  clearPreviewQuarantineDetails,
  clearQuarantineDetails,
  clearInvoiceDetails,
  clearMonitorQuarantine,
  clearProductsWithoutSN,
  clearProductsWithCustomSn,
  toggleProductWithCustomSnValidation,
  setProductWithCustomBc,
} = receipt.actions;

export default receipt.reducer;
