import { useNavigate } from "react-router-dom";
import { TableItemId } from "../../../../components/tables/TableWidget";
import {
  crudRoutes,
  receptionRoutes,
} from "../../../../router/routesConstants";
import Text from "../../../../styleguide/Text";
import { setProductWithCustomBc } from "../../../../redux/reducer/receipt.reducer";
import { useAppDispatch } from "../../../../redux/hooks";
import { ProductsWithCustomSnListInterface } from "../../../../redux/interfaces/receipt.interface";
import CustomButton from "../../../../components/CustomButton";

function ProductsWithoutSnActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  crudData: ProductsWithCustomSnListInterface;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createProduct = () => {
    dispatch(setProductWithCustomBc(crudData));

    navigate(
      `/${receptionRoutes.productsWithCustomBc}/${receptionRoutes.productsWithoutSn}/${itemId}/${crudRoutes.create}`,
    );
  };

  return (
    <div className="text-end">
      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        fullWidth
        onClick={createProduct}>
        <Text variant="body2" className="indicator-label" isBold>
          Adauga
        </Text>
      </CustomButton>
    </div>
  );
}

export default ProductsWithoutSnActionButtons;
