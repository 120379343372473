import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getQuarantinedProductsToDestroy } from "../../../redux/api/inventory.api";
import { clearDepositStocks } from "../../../redux/reducer/inventory.reducer";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getQuarantineProductRecordsTableData,
  quarantineProductRecordsTableHeader,
} from "./tableConfig/quaratineProductRecordsTableConfig";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

function QuarantineProductRecords() {
  const dispatch = useAppDispatch();
  const {
    inventory: {
      quarantinedProductsToDestroy: {
        data: { data: produseCarantinate, total: nrTotalProduse },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getQuarantinedProductsToDestroy({
          page: 1,
          perPage: 10000,
          reason: action.tableFilter.reason,
        }),
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearDepositStocks());
    };
  }, [dispatch]);

  return (
    <TableWidget
      tableTitle={"Evidenta produselor aflate in carantina"}
      tableHeaderData={quarantineProductRecordsTableHeader}
      tableItemsData={getQuarantineProductRecordsTableData(produseCarantinate)}
      handleTableActions={handleTableActions}
      totalItems={nrTotalProduse}
      tableLoading={isLoading}
      withGlobalFilter={false}
      withTablePagination={false}
    />
  );
}

export default QuarantineProductRecords;
