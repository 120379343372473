import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearQuarantinedProducts } from "../../../redux/reducer/inventory.reducer";
import { getQuarantinedProductsToDestroy } from "../../../redux/api/inventory.api";
import useTableCtx from "../../../components/tables/tableContext/useTableCtx";

import {
  getQuarantinedProductsToDestroyTableData,
  quarantinedProductsToDestroyTableHeader,
} from "./tableConfigs/quarantinedProductsToDestroyConfig";

import TableWidget from "../../../components/tables/TableWidget";
import CustomButton from "../../../components/CustomButton";
import DestroyProductsModal from "./components/DestroyProductsModal";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

function ProductsDestructionProcedure() {
  const dispatch = useAppDispatch();
  const {
    inventory: {
      quarantinedProductsToDestroy: {
        data: { data: produseCarantinate, total: nrTotalProduse },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getQuarantinedProductsToDestroy({
          page: 1,
          perPage: 10000,
          reason: action.tableFilter.reason,
        }),
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearQuarantinedProducts());
    };
  }, [dispatch]);

  const CustomHeaderComponent = () => {
    const {
      handleCheckAllItems,
      selectedItems,
      setSelectedItems,
      tableFilter,
    } = useTableCtx() || {};

    return (
      <div className="mb-5">
        <CustomButton
          className="me-2"
          onClick={() => handleCheckAllItems && handleCheckAllItems()}>
          Selecteaza toate
        </CustomButton>
        {selectedItems && selectedItems.length > 0 && (
          <DestroyProductsModal
            selectedProductsToDestroy={selectedItems}
            setSelectedItems={setSelectedItems}
            reason={tableFilter.reason}
          />
        )}
      </div>
    );
  };

  return (
    <TableWidget
      tableTitle={"Produse carantinate, eligibile pentru distrugere"}
      CustomHeaderComponent={CustomHeaderComponent}
      tableHeaderData={quarantinedProductsToDestroyTableHeader}
      tableItemsData={getQuarantinedProductsToDestroyTableData(
        produseCarantinate,
      )}
      handleTableActions={handleTableActions}
      totalItems={nrTotalProduse}
      tableLoading={isLoading}
      withGlobalSearch={false}
      withGlobalFilter={false}
      withTablePagination={false}
    />
  );
}

export default ProductsDestructionProcedure;
