import { TableHeaderCategoryConfig } from "../../../components/tables/tableContext/TableContext";
import { ProductsWithCustomSnListInterface } from "../../../redux/interfaces/receipt.interface";
import { dateToString } from "../../../utils/dateAndTime";

export const productsWithCustomBcTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Id produs Erp",
    value: "idProdusErp",
    minW: 125,
  },
  {
    label: "Nume produs",
    value: "numeProdus",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Cod produs",
    value: "codProdus",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Nume grupa",
    value: "numeGrupa",
    minW: 125,
  },
  {
    label: "SN",
    value: "sn",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Tip",
    value: "tip",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Data creare/actualizare",
    value: "created_at",
    minW: 125,
  },
];

export const getProductsWithCustomBcTableData = (
  data: ProductsWithCustomSnListInterface[],
): any =>
  data.map((item) => {
    return {
      id: item.id,
      crudData: item,
      data: [
        {
          title: item.idProdusErp,
        },
        {
          title: item.numeProdus,
        },
        {
          title: item.codProdus,
        },
        {
          title: item.numeGrupa,
        },
        {
          title: item.sn,
        },
        {
          title: item.tip,
        },
        {
          badges: [
            {
              badgeText: dateToString(item.created_at),
              badgeColor: "light-info",
            },
            {
              badgeText: dateToString(item.updated_at),
              badgeColor: "light-warning",
            },
          ],
        },
      ],
    };
  });
