import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { CellsNomenclatureListInterface } from "../../../../redux/interfaces/admin.interface";

const cellsNomenclatureZones = [
  { value: 20, label: "Toate" },
  { value: 0, label: "Orice" },
  { value: 1, label: "Medicament" },
  { value: 2, label: "Dispozitive medicale" },
  { value: 3, label: "Supliment alimentar" },
  { value: 4, label: "Cosmetice" },
  { value: 5, label: "Parafarmaceutice" },
  { value: 6, label: "Frig" },
  { value: 7, label: "Separanda" },
  { value: 8, label: "Biocide" },
];

const cellsNomenclatureTypes = [
  { value: 20, label: "Toate" },
  { value: 1, label: "Retail" },
  { value: 2, label: "Gross" },
  { value: 3, label: "Storage" },
  { value: 4, label: "Carantina" },
  { value: 5, label: "Retrageri" },
  { value: 6, label: "Returnari" },
  { value: 7, label: "Neconforme" },
  { value: 8, label: "Falsificate" },
  { value: 9, label: "Expirate" },
  { value: 10, label: "In curs de expirare" },
];

const getCellsNomenclatureZonesLabel = (status: number) => {
  const getStatus = cellsNomenclatureZones.find(
    (item) => item.value === status,
  );
  return getStatus ? getStatus.label : "";
};

const getCellsNomenclatureTypesLabel = (status: number) => {
  const getStatus = cellsNomenclatureTypes.find(
    (item) => item.value === status,
  );
  return getStatus ? getStatus.label : "";
};

export const cellsNomenclatureTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Cladire",
    value: "cladire",
    minW: 20,
    withSearch: {
      withoutSearchIcon: true,
    },
  },
  {
    label: "Rand",
    value: "rand",
    minW: 20,
    withSearch: {
      withoutSearchIcon: true,
    },
  },
  {
    label: "Coloana",
    value: "coloana",
    minW: 20,
    withSearch: {
      withoutSearchIcon: true,
    },
  },
  {
    label: "Polita",
    value: "polita",
    minW: 20,
    withSearch: {
      withoutSearchIcon: true,
    },
  },
  {
    label: "Celula",
    value: "celula",
    minW: 20,
    withSearch: {
      withoutSearchIcon: true,
    },
  },
  {
    label: "Alee",
    value: "alee",
    minW: 20,
    withSearch: {
      withoutSearchIcon: true,
    },
  },
  {
    label: "Insula",
    value: "insula",
    minW: 20,
    withSearch: {
      withoutSearchIcon: true,
    },
  },
  {
    label: "Lungime celula",
    value: "lungimeCel",
    minW: 20,
  },
  {
    label: "Latime celula",
    value: "latimeCel",
    minW: 20,
  },
  {
    label: "Inaltime celula",
    value: "inaltimeCel",
    minW: 20,
  },
  {
    label: "Greutate maxima celula",
    value: "maxKgCel",
    minW: 20,
  },
  {
    label: "Lungime disponibila",
    value: "lungimeDisponibila",
    minW: 20,
  },
  {
    label: "Zona",
    value: "zona",
    withFilter: cellsNomenclatureZones,
    minW: 20,
  },
  {
    label: "Tip",
    value: "type",
    withFilter: cellsNomenclatureTypes,
    minW: 20,
  },
];

export const getCellsNomenclatureTableData: any = (
  reportsUserActivityList: CellsNomenclatureListInterface[],
) =>
  reportsUserActivityList?.map((item) => {
    return {
      id: item.idCelula,
      crudData: item,
      data: [
        {
          title: item.cladire,
        },
        {
          title: item.rand,
        },
        {
          title: item.coloana,
        },
        {
          title: item.polita,
        },
        {
          title: item.celula,
        },
        {
          title: item.alee ?? "-",
        },
        {
          title: item.insula ?? "-",
        },
        {
          title: item.lungimeCel,
        },
        {
          title: item.latimeCel,
        },
        {
          title: item.inaltimeCel,
        },
        {
          title: item.maxKgCel,
        },
        {
          title: item.lungimeDisponibila,
        },

        {
          badges: [
            {
              badgeText: getCellsNomenclatureZonesLabel(item.zona),
              badgeColor: "light-primary",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: getCellsNomenclatureTypesLabel(item.type),
              badgeColor: "light-primary",
            },
          ],
        },
      ],
    };
  });
