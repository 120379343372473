import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../components/tables/tableContext/TableContext";

import { dateToString } from "../../../../utils/dateAndTime";

import { DuplicateProductsInterface } from "../../../../redux/interfaces/inventory.interface";

export const duplicateProductsTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nume produs",
    value: "numeProdus",
    minW: 125,
  },
  {
    label: "Cod produs",
    value: "codProdus",
    minW: 125,
  },
  {
    label: "Lot",
    value: "lot",
    minW: 125,
  },
  {
    label: "Data expirare",
    value: "dataExpirare",
    minW: 125,
  },
  {
    label: "Stocuri",
    value: "valStoc",
    minW: 125,
  },
  {
    label: "Locatie",
    value: "type",
    minW: 125,
    withFilter: [
      {
        label: "Raft",
        value: 1,
      },
      {
        label: "Gross",
        value: 2,
      },
    ],
  },
];

export const getDuplicateProductsTableData = (
  data: DuplicateProductsInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.codProdus,
      crudData: item,
      data: [
        {
          title: item.numeProdus,
        },
        {
          title: item.codProdus,
        },
        {
          title: item.lot,
        },
        {
          badges: [
            {
              badgeText: dateToString(item.dataExpirare),
              badgeColor: "light-info",
            },
          ],
        },
        { title: item.valStoc },
        {
          title: item.locatie,
        },
      ],
    };
  });
