import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { VerificationZonesListInterface } from "../../../../../redux/interfaces/picking.interface";

const verificationZonesTableStatus = [
  { value: false, label: "Deblocata" },
  { value: true, label: "Blocata" },
];

const getStatusLabel = (status: any) => {
  const currentStatus = verificationZonesTableStatus.find(
    (item) => item.value === status,
  );

  return currentStatus?.label;
};

const verificationZonesTableaActivated = [
  { value: 0, label: "Activat" },
  { value: 1, label: "Dezactivat" },
];


const getActivatedLabel = (status: any) => {
  const currentStatus = verificationZonesTableaActivated.find(
    (item) => item.value === status,
  );

  return currentStatus?.label;
};

export const verificationZonesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Cod zona",
    value: "codZona",
    minW: 125,
  },
  {
    label: "Ip imprimanta",
    value: "ipImprimanta",
    minW: 125,
  },
  {
    label: "Port imprimanta",
    value: "portImprimanta",
    minW: 125,
  },
  {
    label: "Ip zebra",
    value: "ip",
    minW: 125,
  },
  {
    label: "Port zebra",
    value: "portZebra",
    minW: 125,
  },
  {
    label: "Blocat",
    value: "status",
    minW: 125,
  },
  {
    label: "Activ",
    value: "activ",
    minW: 125,
  },
];

export const getVerificationZonesTableData = (
  data: VerificationZonesListInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.idZona,
      crudData: item,
      data: [
        {
          title: item.codZona,
        },
        {
          title: item.ipImprimanta,
        },
        {
          title: item.portImprimanta,
        },
        {
          title: item.ip,
        },
        {
          title: item.portZebra,
        },
        {
          badges: [
            {
              badgeText: getStatusLabel(item.status),
              badgeColor: !item.status ? "light-success" : "light-danger",
              style: { minWidth: "100px" },
            },
          ],
        },
        {
          badges: [
            {
              badgeText: getActivatedLabel(item.deleted),
              badgeColor: !item.deleted ? "light-success" : "light-danger",
              style: { minWidth: "100px" },
            },
          ],
        },
      ],
    };
  });
