import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget from "../../../components/tables/TableWidget";
import { getReturningProductsToProviderRaports } from "../../../redux/api/reports.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { dateForDatabase } from "../../../utils/dateAndTime";
import ReturningProductsReportsActionButton from "./TableActionButtonsComponents/ReturningProductsReportsActionButton";
import {
  getReturningProductsToProviderReportsTableData,
  returningProductsToProviderReportsTableHeader,
} from "./tableConfigs/returningProductsToProviderReportsTableConfig";

function ReturningProductsReports() {
  const dispatch = useAppDispatch();
  const {
    reports: {
      returningProductsToProvider: {
        data: { data: returningProductsToProviderList, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    reports: state.reports,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      const formatDate = dateForDatabase(action.tableSearch.dataPV);

      dispatch(
        getReturningProductsToProviderRaports({
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          user: action.tableSearch.user,
          provider: action.tableSearch.provider,
          dataPV: formatDate,
          docNrAndDate: action.tableSearch.docNrAndDate,
          productName: action.tableSearch.productName,
          lot: action.tableSearch.lot,
        }),
      );
    }
  };

  const sortedreturningProductsToProviderList = [
    ...returningProductsToProviderList,
    // @ts-ignore
  ].sort((a, b) => {
    if (a.dataPV === b.dataPV) {
      if (a.nr_raport > b.nr_raport) {
        return -1;
      }
      if (a.nr_raport < b.nr_raport) {
        return 1;
      }
      return 0;
    }
  });

  return (
    <TableWidget
      tableTitle={"Rapoarte returnare produse catre furnizor"}
      tableHeaderData={returningProductsToProviderReportsTableHeader}
      tableItemsData={getReturningProductsToProviderReportsTableData(
        sortedreturningProductsToProviderList,
      )}
      totalItems={total}
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
      ActionButtonsComponent={(props: any) => (
        <ReturningProductsReportsActionButton {...props} />
      )}
      withGlobalSearch={false}
      withGlobalFilter={false}
    />
  );
}

export default ReturningProductsReports;
