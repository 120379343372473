import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import {
  ReturnedProductsToProviderListInterface,
  ReturningProductsToProviderReportsInterface,
} from "../../../../redux/interfaces/inventory.interface";
import { dateToString } from "../../../../utils/dateAndTime";

const docTypes = [
  {
    value: 1,
    label: "Aviz de insotire a marfii",
  },
  {
    value: 2,
    label: "Factura de retur",
  },
];

const getDocTypesLabel = (status: number) => {
  const getStatus = docTypes.find((item) => item.value === status);
  return getStatus ? getStatus.label : "";
};

export const returningProductsToProviderReportsTableHeader: TableHeaderCategoryConfig[] =
  [
    {
      label: "Nr. raport",
      value: "nr_raport",
      minW: 125,
    },
    {
      label: "Utilizator",
      value: "user",
      minW: 125,
      withSearch: {
        placeholder: "Nume utilizator",
      },
    },
    {
      label: "Data creare PV",
      value: "dataPV",
      minW: 125,
      withDatePicker: {
        placeholder: "Data creare PV",
        maxDate: new Date(),
        withClearData: true,
      },
    },
    {
      label: "Furnizor",
      value: "provider",
      minW: 125,
      withSearch: {
        placeholder: "Nume furnizor",
      },
    },
    {
      label: "Tip doc retur",
      value: "docType",
      minW: 125,
    },
    {
      label: "Nr. si data doc",
      value: "docNrAndDate",
      minW: 125,
      withSearch: {
        placeholder: "Nr. doc",
      },
    },
    {
      label: "",
      value: "",
      minW: 125,
    },
    {
      label: "",
      value: "lot",
      minW: 125,
      withSearch: {
        placeholder: "Lot produs",
      },
    },
    {
      label: "",
      value: "productName",
      minW: 50,
      withSearch: {
        placeholder: "Nume produs",
      },
    },
  ];

export const getReturningProductsToProviderReportsTableData: any = (
  returningProductsToProviderRaportsListList: ReturningProductsToProviderReportsInterface[],
) => {
  const extraHeaderCategories = [
    "",
    "",
    "Nume",
    "Cod grupa",
    "",
    "",
    "",
    "Lot",
    "Cantitate",
    "",
  ].map((item, idx) => {
    if (idx === 0) {
      return {
        title: "",
      };
    }
    return {
      badges: [
        {
          badgeText: item,
          badgeColor: "secondary",
        },
      ],
    };
  });

  const getExtraDataItems = (
    products: ReturnedProductsToProviderListInterface[],
  ) =>
    products?.map((product) => {
      return [
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: product.numeProdusErp,
        },
        {
          title: product.codGrupa ? product.codGrupa : "-",
        },
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: product.lot,
        },
        {
          title: product.cantitate,
        },
        {
          title: "",
        },
      ];
    });

  return returningProductsToProviderRaportsListList?.map((item) => {
    return {
      id: item.nr_raport,
      crudData: item,
      extraData: [extraHeaderCategories, ...getExtraDataItems(item.products)],

      data: [
        {
          title: item.nr_raport,
        },
        {
          title: item.userName,
        },
        {
          title: dateToString(item.dataPV),
        },
        {
          title: item.provider,
        },
        {
          title: getDocTypesLabel(item.docType),
        },
        {
          title: item.docNrAndDate,
        },
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
      ],
    };
  });
};
