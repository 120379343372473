import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  QuarantinedProductsToReturnStockInterface,
  ReturningProductsType,
} from "../../../../redux/interfaces/inventory.interface";
import { postProdToReturn } from "../../../../redux/api/inventory.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

import CustomButton from "../../../../components/CustomButton";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import TextInput from "../../../../components/misc/TextInput";
import Text from "../../../../styleguide/Text";

import Dropdown, { DropdownValue } from "../../../../components/misc/Dropdown";
import ContentCard from "../../../../components/misc/ContentCard";
import { returningProductTypes } from "../../../../constants/general";

const ReturningProductsModal = ({
  selectedReturningProducts,
  setSelectedItems,
}: {
  selectedReturningProducts: QuarantinedProductsToReturnStockInterface[];
  setSelectedItems: any;
}) => {
  const dispatch = useAppDispatch();

  const [isReturningModalOpen, setIsReturningModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [returningProducts, setReturningProducts] = useState<
    ReturningProductsType[]
  >([]);
  const [returnDocNrAndDate, setReturnDocNrAndDate] = useState<
    string | number | null
  >(null);
  const [selectedDocType, setSelectedDocType] = useState<DropdownValue | null>(
    null,
  );
  const [providersData, setProvidersData] = useState<DropdownValue[]>([]);
  const [selectedProvider, setSelectedProvider] =
    useState<DropdownValue | null>(null);

  useEffect(() => {
    if (selectedReturningProducts.length && selectedProvider) {
      setReturningProducts(selectedReturningProducts);
    }
  }, [dispatch, selectedReturningProducts, selectedProvider]);

  useEffect(() => {
    if (isReturningModalOpen && selectedReturningProducts.length) {
      const allProviders = selectedReturningProducts
        .map((item) => item.furnizori) // here we will get multiple arrays of furnizori
        .flat(); // flat will combine them all into one array

      const uniqueProviders = allProviders.filter(
        (furnizor, index, self) =>
          self.findIndex((f) => f.id === furnizor.id) === index, // we remove duplicates
      );

      const counts: Record<number, number> = {};
      let mostFrequentProvider: any = null;
      let maxCount = 0;

      for (const provider of allProviders) {
        counts[provider.id] = (counts[provider.id] || 0) + 1;

        if (counts[provider.id] > maxCount) {
          maxCount = counts[provider.id];
          mostFrequentProvider = provider;
        }
      }

      setProvidersData(uniqueProviders);

      setSelectedProvider(mostFrequentProvider);
    }
  }, [isReturningModalOpen, selectedReturningProducts]);

  useEffect(() => {
    if (!isReturningModalOpen) {
      setReturningProducts([]);
      setReturnDocNrAndDate(null);
      setSelectedDocType(null);
      setSelectedProvider(null);
    }
  }, [isReturningModalOpen]);

  const handleReturningProductsModalShow = (show: boolean) => {
    setIsReturningModalOpen(show || false);
    return;
  };

  const handleChangeProductQuantity = (
    id: string,
    value: string | number | null,
  ) => {
    setReturningProducts((prev) => {
      return prev.map((product) => {
        if (id === product.id) {
          if (Number(value) <= product.cantitate && Number(value) >= 0) {
            return {
              ...product,
              quantityToReturn: value ? Number(value) : null,
            };
          }
        }
        return product;
      });
    });
  };

  const handleChangeProductReturningDocType = (
    id: string | number,
    value: string | number | null,
  ) => {
    if (value) {
      setSelectedDocType({
        id: id,
        label: value,
      });
    }
  };

  const handleChangeProductReturningProvider = (
    id: string | number,
    value: string | number | null,
  ) => {
    if (value) {
      setSelectedProvider({
        id: id,
        label: value,
      });
    }
  };

  const handleRemoveProduct = (product: ReturningProductsType) => {
    setReturningProducts((prev) => {
      return prev.filter((item) => item.id !== product.id);
    });
  };

  const handleSaveReturningProducts = async () => {
    if (!selectedDocType?.id || !selectedProvider?.id) {
      return;
    }
    setIsLoading(true);

    const resultAction = await dispatch(
      postProdToReturn({
        productsToReturn: returningProducts,
        page: 1,
        perPage: 9999,
        docType: selectedDocType?.id,
        provider: selectedProvider?.id,
        docNrAndDate: returnDocNrAndDate,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postProdToReturn) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postProdToReturn) ===
      endpointStatus.fulfilled
    ) {
      setSelectedItems([]);
    }
  };

  const isSubmitDisabled =
    !returnDocNrAndDate ||
    returningProducts?.some((product) => !product.quantityToReturn);

  const onChangeDocReturValue = (value: string | number | null) => {
    setReturnDocNrAndDate(value);
  };

  return (
    <>
      <CustomButton
        onClick={() => handleReturningProductsModalShow(true)}
        variant="contained">
        Returnare
      </CustomButton>
      <ModalWrapper
        title="Retur produse la furnizor"
        modalSize="xl"
        fullscreen="lg-down"
        show={isReturningModalOpen}
        handleClose={() => handleReturningProductsModalShow(false)}>
        <Dropdown
          className="my-4"
          title="Catre furnizorul"
          data={providersData}
          singleValue={selectedProvider}
          setSingleValue={(value) => {
            if (value?.id) {
              handleChangeProductReturningProvider(value.id, value.label);
            }
          }}
        />
        <Dropdown
          title="Tip doc retur"
          className="ms-2"
          data={returningProductTypes}
          singleValue={selectedDocType}
          setSingleValue={(value) => {
            if (value?.id) {
              handleChangeProductReturningDocType(value.id, value.label);
            }
          }}
        />

        <TextInput
          name="docRetur"
          value={returnDocNrAndDate}
          label="Nr. si data doc de retur"
          onChange={onChangeDocReturValue}
        />
        {returningProducts?.map((product) => {
          const isSameProviderAsSelected = product.furnizori.some(
            (provider) => provider.id === selectedProvider?.id,
          );
          return (
            <ContentCard
              key={product.idActivCel}
              className={
                !isSameProviderAsSelected ? "border border-danger" : ""
              }>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <Text
                    variant="h3"
                    component="h3"
                    className={`card-title mb-4 me-2`}
                    gutterBottom>
                    {product.numeProdusErp}
                  </Text>
                  {!isSameProviderAsSelected && (
                    <CustomButton
                      variant="contained"
                      size="small"
                      color="error"
                      style={{
                        height: 40,
                      }}
                      onClick={() => handleRemoveProduct(product)}>
                      X
                    </CustomButton>
                  )}
                </div>

                <TextInput
                  type="number"
                  name={product.numeProdusErp}
                  label={"Cantitate"}
                  value={product.quantityToReturn}
                  placeholder="Cantitate de returnat"
                  disabled={!isSameProviderAsSelected}
                  onChange={(value) =>
                    handleChangeProductQuantity(product.id, value)
                  }
                  endComponent={
                    <Text
                      variant="body1"
                      color={"royalblue"}
                      className="rounded bg-white p-2">
                      Cantitate totala: {product.cantitate}
                    </Text>
                  }
                />
                {!isSameProviderAsSelected && (
                  <Text
                    variant="body1"
                    color={"red"}
                    className="rounded bg-white p-2">
                    Furnizorul nu coincide cu produsul selectat!
                  </Text>
                )}
              </div>
            </ContentCard>
          );
        })}

        <CustomButton
          type="submit"
          variant="contained"
          fullWidth
          className="btn btn-lg btn-primary w-100 mt-5"
          disabled={isSubmitDisabled}
          loading={isLoading}
          onClick={handleSaveReturningProducts}>
          <Text variant="body1" className="indicator-label" isBold>
            Salveaza
          </Text>
        </CustomButton>
      </ModalWrapper>
    </>
  );
};

export default ReturningProductsModal;
