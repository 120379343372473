import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getSelectCellNomenclature,
  postCreateOrUpdateCellNomenclature,
} from "../../../../redux/api/admin.api";
import { clearCellNomenclature } from "../../../../redux/reducer/admin.reducer";

import { Formik } from "formik";
import * as Yup from "yup";

import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

import ContentCard from "../../../../components/misc/ContentCard";
import TextInput from "../../../../components/misc/TextInput";
import CustomButton from "../../../../components/CustomButton";
import { Box } from "@mui/material";
import Dropdown, { DropdownValue } from "../../../../components/misc/Dropdown";

interface initialValuesInterface {
  idCelula?: number | null;
  cladire: string | null;
  rand: string | null;
  coloana: string | null;
  polita: string | null;
  celula: string | null;
  alee: string | null;
  insula: string | null;
  lungimeCel: number | null;
  latimeCel: number | null;
  inaltimeCel: number | null;
  maxKgCel: number | null;
  lungimeDisponibila: number | null;
  zona: number | null;
  type: number | null;
}

const initialValues: initialValuesInterface = {
  idCelula: null,
  cladire: null,
  rand: null,
  coloana: null,
  polita: null,
  celula: null,
  alee: null,
  insula: null,
  lungimeCel: null,
  latimeCel: null,
  inaltimeCel: null,
  maxKgCel: null,
  lungimeDisponibila: null,
  zona: null,
  type: null,
};

// Validation schema
const productSchema = Yup.object({
  cladire: Yup.string().nullable().required("Campul este obligatoriu"),
  rand: Yup.string().nullable().required("Campul este obligatoriu"),
  coloana: Yup.string().nullable().required("Campul este obligatoriu"),
  polita: Yup.string().nullable().required("Campul este obligatoriu"),
  celula: Yup.string().nullable().required("Campul este obligatoriu"),
  lungimeDisponibila: Yup.number()
    .nullable()
    .required("Campul este obligatoriu"),
});

const zonesDataDropdownValue = [
  { id: 20, label: "Toate" },
  { id: 0, label: "Orice" },
  { id: 1, label: "Medicament" },
  { id: 2, label: "Dispozitive medicale" },
  { id: 3, label: "Supliment alimentar" },
  { id: 4, label: "Cosmetice" },
  { id: 5, label: "Parafarmaceutice" },
  { id: 6, label: "Frig" },
  { id: 7, label: "Separanda" },
  { id: 8, label: "Biocide" },
];

const typesDataDropdownValue = [
  { id: 20, label: "Toate" },
  { id: 1, label: "Retail" },
  { id: 2, label: "Gross" },
  { id: 3, label: "Storage" },
  { id: 4, label: "Carantina" },
  { id: 5, label: "Retrageri" },
  { id: 6, label: "Returnari" },
  { id: 7, label: "Neconforme" },
  { id: 8, label: "Falsificate" },
  { id: 9, label: "Expirate" },
  { id: 10, label: "In curs de expirare" },
];

function CreateOrUpdateCellsNomenclature() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    admin: {
      selectCellNomenclature: {
        data: cellNomenclatureData,
        isLoading: isLoadingCellNomenclature,
      },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const { routeParams } = useRoutesHelper();
  const { idCelula } = routeParams || {};

  const [isLoading, setIsLoading] = useState(false);
  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [selectedZone, setSelectedZone] = useState<DropdownValue | null>(null);
  const [selectedType, setSelectedType] = useState<DropdownValue | null>(null);

  useEffect(() => {
    if (cellNomenclatureData) {
      const currentZone = zonesDataDropdownValue.find(
        (zona) => zona.id === cellNomenclatureData.zona,
      );
      setSelectedZone(currentZone || null);

      const currentType = typesDataDropdownValue.find(
        (type) => type.id === cellNomenclatureData.type,
      );
      setSelectedType(currentType || null);
    }
  }, [cellNomenclatureData]);

  useEffect(() => {
    if (idCelula) {
      dispatch(getSelectCellNomenclature(idCelula));
    } else {
      setFormikValues(initialValues);
    }
  }, [dispatch, idCelula]);

  useEffect(() => {
    if (cellNomenclatureData) {
      setFormikValues((prev) => ({
        ...prev,
        idCelula: cellNomenclatureData?.idCelula || null,
        cladire: cellNomenclatureData?.cladire || null,
        rand: cellNomenclatureData?.rand || null,
        coloana: cellNomenclatureData?.coloana || null,
        polita: cellNomenclatureData?.polita || null,
        celula: cellNomenclatureData?.celula || null,
        alee: cellNomenclatureData?.alee || null,
        insula: cellNomenclatureData?.insula || null,
        lungimeCel: cellNomenclatureData?.lungimeCel || null,
        latimeCel: cellNomenclatureData?.latimeCel || null,
        inaltimeCel: cellNomenclatureData?.inaltimeCel || null,
        maxKgCel: cellNomenclatureData?.maxKgCel || null,
        lungimeDisponibila: cellNomenclatureData?.lungimeDisponibila || null,
        zona: cellNomenclatureData?.zona || null,
        type: cellNomenclatureData?.type || null,
      }));
    }
  }, [cellNomenclatureData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveCellNomenclature = async (values: initialValuesInterface) => {
    const payload = {
      idCelula: idCelula || null,
      ...values,
    };

    setIsLoading(true);

    const resultAction = await dispatch(
      postCreateOrUpdateCellNomenclature({ params: payload }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateCellNomenclature) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateCellNomenclature) ===
      endpointStatus.fulfilled
    ) {
      handleGoBack();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearCellNomenclature());
    };
  }, [dispatch]);

  return (
    <>
      <ContentCard
        cardTitle={`${idCelula ? " Editeaza celula" : "Creeaza celula"}`}
        isLoading={isLoadingCellNomenclature}>
        <Formik
          enableReinitialize
          initialValues={formikValues}
          validationSchema={productSchema}
          onSubmit={handleSaveCellNomenclature}>
          {({
            isValid,
            errors,
            touched,
            getFieldProps,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                noValidate
                id="kt_login_signin_form">
                <Box className="d-flex">
                  <Dropdown
                    className="mb-4 me-2"
                    title="Selecteaza zona"
                    data={zonesDataDropdownValue}
                    singleValue={selectedZone}
                    setSingleValue={(value) => {
                      setSelectedZone(value);
                      setFieldValue("zona", value?.id);
                    }}
                  />

                  <Dropdown
                    className="mb-4 me-2"
                    title="Selecteaza tipul"
                    data={typesDataDropdownValue}
                    singleValue={selectedType}
                    setSingleValue={(value) => {
                      setSelectedType(value);
                      setFieldValue("type", value?.id);
                    }}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Cladire"
                    name="cladire"
                    error={errors["cladire"]}
                    touched={touched["cladire"]}
                    inputProps={getFieldProps("cladire")}
                  />
                </Box>

                <Box className="d-flex">
                  <TextInput
                    className="mb-4 me-2"
                    label="Rand"
                    name="rand"
                    error={errors["rand"]}
                    touched={touched["rand"]}
                    inputProps={getFieldProps("rand")}
                  />

                  <TextInput
                    className="mb-4 me-2"
                    label="Coloana"
                    name="coloana"
                    error={errors["coloana"]}
                    touched={touched["coloana"]}
                    inputProps={getFieldProps("coloana")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Polita"
                    name="polita"
                    error={errors["polita"]}
                    touched={touched["polita"]}
                    inputProps={getFieldProps("polita")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Celula"
                    name="celula"
                    error={errors["celula"]}
                    touched={touched["celula"]}
                    inputProps={getFieldProps("celula")}
                  />
                </Box>

                <Box className="d-flex">
                  <TextInput
                    className="mb-4 me-2"
                    label="Alee"
                    name="alee"
                    error={errors["alee"]}
                    touched={touched["alee"]}
                    inputProps={getFieldProps("alee")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Insula"
                    name="insula"
                    error={errors["insula"]}
                    touched={touched["insula"]}
                    inputProps={getFieldProps("insula")}
                  />
                </Box>

                <Box className="d-flex">
                  <TextInput
                    className="mb-4 me-2"
                    label="Lungime celula (mm)"
                    name="lungimeCel"
                    error={errors["lungimeCel"]}
                    touched={touched["lungimeCel"]}
                    inputProps={getFieldProps("lungimeCel")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Latime celula (mm)"
                    name="latimeCel"
                    error={errors["latimeCel"]}
                    touched={touched["latimeCel"]}
                    inputProps={getFieldProps("latimeCel")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Inaltime celula (mm)"
                    name="inaltimeCel"
                    error={errors["inaltimeCel"]}
                    touched={touched["inaltimeCel"]}
                    inputProps={getFieldProps("inaltimeCel")}
                  />

                  <TextInput
                    className="mb-4 me-2"
                    label="Max kg celula (kg)"
                    name="maxKgCel"
                    error={errors["maxKgCel"]}
                    touched={touched["maxKgCel"]}
                    inputProps={getFieldProps("maxKgCel")}
                  />
                  <TextInput
                    className="mb-4 me-2"
                    label="Lungime disponibila (mm)"
                    name="lungimeDisponibila"
                    error={errors["lungimeDisponibila"]}
                    touched={touched["lungimeDisponibila"]}
                    inputProps={getFieldProps("lungimeDisponibila")}
                  />
                </Box>

                <div className="d-flex justify-content-between">
                  <CustomButton variant="outlined" onClick={handleGoBack}>
                    Anuleaza
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || isLoading}
                    disabled={!isValid || isSubmitting}>
                    Salveaza
                  </CustomButton>
                </div>
              </form>
            );
          }}
        </Formik>
      </ContentCard>
    </>
  );
}

export default CreateOrUpdateCellsNomenclature;
