import React, { useEffect } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getProductsThatExpireTableData,
  productsThatExpireTableHeader,
} from "./tableConfigs/productsThatExpireConfig";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearDepositStocks } from "../../../redux/reducer/inventory.reducer";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { getProductsThatExpire } from "../../../redux/api/inventory.api";

function ProductsThatExpire() {
  const dispatch = useAppDispatch();
  const {
    inventory: {
      productsThatExpire: {
        data: { data: produse, total: nrTotalProduse },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearDepositStocks());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getProductsThatExpire({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          typeFilter: action.tableFilter.typeFilter,
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Produse care expira in curand"}
      tableHeaderData={productsThatExpireTableHeader}
      tableItemsData={getProductsThatExpireTableData(produse)}
      totalItems={nrTotalProduse}
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
      withGlobalSearch={false}
      withGlobalFilter={false}
      withTablePagination
    />
  );
}

export default ProductsThatExpire;
