export default {
  // RECEPTION
  CREATE_RECEPTION: "create_reception",
  EDIT_RECEPTION: "edit_reception",
  RECEPTION: "reception",
  ATTACH_INVOICE: "attach_invoice",
  NRCD: "nrcd",
  QUARANTINE: "quarantine",
  PREVIEW_QUARANTINE: "preview_quarantine",
  RECEIVED_PRODUCTS: "received_products",
  EDIT_RECEIVED_PRODUCTS: "edit_received_products",
  TRANSFER_RECEIVED: "transfer_received",
  TRANSFER_RECEIVED_SPLIT: "transfer_received_split",
  TRANSFER_TASKS: "transfer_tasks",
  RAPORTS: "raports",
  MONITOR_QUARANTINE: "monitor_quarantine",
  RETURNS: "returns",
  CREATE_RETURN: "create_return",
  PRODUCTS_WITHOUT_SN: "products_without_sn",
  PRODUCTS_WITH_CUSTOM_BC: "products_with_custom_cb",
  PRODUCTS_WITH_CUSTOM_BC_CREATE: "products_with_custom_cb_edit",
  // PUTAWAY
  PUTAWAY_TASKS: "putaway_tasks",
  PUTAWAY_REARRANGEMENT_TASKS: "putaway_rearrangement_tasks",
  PUTAWAY_REFILL_TASKS: "putaway_refill_tasks",
  // PICKING
  VERIFY_COMMAND: "verify_command",
  PRINT_COMMAND_INVOICES: "print_command_invoices",
  PRINT_COMMAND_PACKAGES: "print_command_packages",
  DELIVERY_ARRANGEMENT: "delivery_arrangement",
  SPLIT_INVOICES: "split_invoices",
  VERIFICATION_ZONES: "verification_zones",
  EDIT_VERIFICATION_ZONE: "edit_verification_zone",
  // PICKING CU AVIZ
  AVIZ_VERIFY_COMMAND: "aviz_verify_command",
  AVIZ_PRINT_COMMAND_INVOICES: "aviz_print_command_invoices",
  AVIZ_PRINT_COMMAND_PACKAGES: "aviz_print_command_packages",
  AVIZ_DELIVERY_ARRANGEMENT: "aviz_delivery_arrangement",
  AVIZ_VERIFICATION_ZONES: "aviz_verification_zones",
  // EXPEDITION
  SCHEDULE_WORKING_POINTS: "schedule_working_points",
  WORKING_POINTS_ORGANIZE: "working_points_organize",
  SCHEDULE_WORKING_ROUTE: "schedule_working_route",
  WORKING_ROUTES_ORGANIZE: "working_routes_organize",
  NODES: "nodes",
  EDIT_NODES_ROUTES: "edit_nodes_routes",
  INCOMPLETE_INVOICES: "incomplete_invoices",
  SCAN_PLASTIC_BOXES: "scan_plastic_boxes",
  VERIFY_PLASTIC_BOXES: "verify_plastic_boxes",
  MONITORING_PLASTIC_BOXES: "monitoring_plastic_boxes",
  BORDEROU_HISTORY: "borderou_history",
  SEARCH_PACKAGE_BY_OP: "search_package_by_op",
  // INVENTORY
  INVENTORY_RECEIVED_PRODUCTS: "inventory_received_products",
  STOCKS: "stocks",
  DEPOSIT_STOCKS_ACTION: "deposit_stocks_action",
  NOMENCLATURE: "nomenclature",
  EDIT_PRODUCT_NOMENCLATURE: "edit_product_nomenclature",
  EDIT_PACKAGE_NOMENCLATURE: "edit_package_nomenclature",
  EDIT_LIMIT_NOMENCLATURE: "edit_limit_nomenclature",
  COPY_PRODUCT_NOMENCLATURE: "copy-product-nomenclature",
  DEACTIVATE_PRODUCT_NOMENCLATURE: "deactivate_product_nomenclature",
  PRODUCTS_THAT_EXPIRE_SOON: "products_that_expire_soon",
  PRODUCTS_DESTRUCTION_PROCEDURE: "products_destruction_procedure",
  PRODUCTS_DESTROYED_RAPORTS: "products_destroyed_raports",
  DUPLICATE_PRODUCTS: "duplicate_products",
  RETURNING_PRODUCTS_TO_PROVIDER: "returning_products_to_provider",
  // REPORTS
  USERS_ACTIVITY: "users_activity",
  USERS_EXTRA_ACTIVITIES: "users_extra_activities",
  QUARANTINE_PRODUCT_RECORDS: "quarantine_product_records",
  // ADMIN
  PREVIEW_USERS: "preview_users",
  CREATE_USER: "create_user",
  EDIT_USER: "edit_user",
  ADMINISTRATE_USER_ROLES: "administrate_user_web_roles",
  ADMINISTRATE_USER_MOBILE_ROLES: "administrate_user_mobile_roles",
  CELLS_NOMENCLATURE: "cells_nomenclature",
  EDIT_CELL_NOMENCLATURE: "edit_cell_nomenclature",
};
