import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUser } from "../api/user.api";
import userRoles from "../../constants/userRoles";
import { isDevelop } from "../../helpers/apiHelper";

export interface StateUserInterface {
  isLoadingUser: boolean;
  userId?: null | number;
  userName?: null | string;
  userRoutesAccess: string[];
  userDepartment?: null | number;
  userFetchingStatus?: null | string;
  isErrorUser?: boolean;
  errorMessageUser?: string;
}

export const defaultStateUser: StateUserInterface = {
  isLoadingUser: false,
  userId: null,
  userName: null,
  userDepartment: null,
  userFetchingStatus: null,
  userRoutesAccess: [],
  isErrorUser: false,
  errorMessageUser: "",
};

const user = createSlice({
  name: "user",
  initialState: defaultStateUser,
  reducers: {
    setUser(
      state,
      action: PayloadAction<{
        token: string;
      }>,
    ) {
      return {
        ...state,
        isLoadingUser: false,
        token: action.payload.token,
      };
    },
    removeUser() {
      return { ...defaultStateUser, isLoadingUser: false };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(fetchUser.pending, () => {
      return {
        ...defaultStateUser,
        isLoadingUser: true,
        errorMessageUser: "",
        isErrorUser: false,
      };
    });
    builder.addCase(fetchUser.rejected, (state, action: any) => {
      return {
        ...state,
        isLoadingUser: false,
        errorMessageUser: action.payload?.message,
        isErrorUser: true,
      };
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const userRoutes = action.payload.userRoutesAccess || [];
      const userRoutesAccess = isDevelop
        ? [...Object.values(userRoles), ...userRoutes]
        : userRoutes;
      return {
        ...state,
        isLoadingUser: false,
        userId: action.payload.userId,
        userName: action.payload.userName,
        // if you add a new page and wan't to test it before it is added to the database, hardcode it below
        userRoutesAccess: userRoutesAccess,
        userDepartment: action.payload.userDepartment,
      };
    });
  },
});

export const { setUser, removeUser } = user.actions;

export default user.reducer;
