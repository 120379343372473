import CustomButton from "../../../../components/CustomButton";
import userRoles from "../../../../constants/userRoles";
import { pickingRoutes } from "../../../../router/routesConstants";
import usePermissions from "../../../../router/usePermissions";
import Text from "../../../../styleguide/Text";

function VerificationZonesCustomHeader() {
  const { isAuthorized } = usePermissions();

  const goToCreateVerificationZone = () => {
    return `/${pickingRoutes.picking}/${pickingRoutes.verificationZones}/${pickingRoutes.createVerificationZone}`;
  };

  return (
    <div className="d-flex justify-content-between align-items-center mb-6">
      <Text variant="h3" component="h3" className="card-title">
        Zone de verificare
      </Text>
      {isAuthorized(userRoles.EDIT_VERIFICATION_ZONE) && (
        <CustomButton
          variant="contained"
          navigate={goToCreateVerificationZone()}>
          Creeaza
        </CustomButton>
      )}
    </div>
  );
}

export default VerificationZonesCustomHeader;
