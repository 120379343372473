import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";

import { UsersListInterface } from "../../../../redux/interfaces/admin.interface";

import moment from "moment";
import "moment/locale/ro";

import Dropdown, { DropdownValue } from "../../../../components/misc/Dropdown";
import { getCurrentYearOrMonth } from "../../../../utils/dateAndTime";
import useTableCtx from "../../../../components/tables/tableContext/useTableCtx";

const lastThreeYears = [
  getCurrentYearOrMonth("year"),
  getCurrentYearOrMonth("year") - 1,
  getCurrentYearOrMonth("year") - 2,
];

const usersRole = [
  { id: "putAway", label: "Putaway" },
  { id: "rearanjare", label: "Rearanjare" },
  { id: "umplere", label: "Umplere" },
  { id: "comenziColectate", label: "Comenzi colectate" },
  { id: "comenziVerificate", label: "Comenzi verificate" },
  { id: "produseReceptionate", label: "Produse receptionate" },
  { id: "produseSolutionate", label: "Produse solutionate" },
  { id: "receptiiDeschise", label: "Receptii deschise" },
];

const monthsDataDropdownValue = moment
  .monthsShort()
  .map((month: string, index: number) => {
    return {
      id: index + 1,
      label: month,
    };
  });

const yearsDataDropdownValue = lastThreeYears.map((year: number) => {
  return {
    id: year,
    label: year,
  };
});

const usersRoleDropdownValue = usersRole.map((role) => {
  return {
    id: role.id,
    label: role.label,
  };
});

function DropdownsPageHeader() {
  const { handleTableSearch } = useTableCtx() || {};

  const [selectedMonth, setSelectedMonth] = useState<any>(
    monthsDataDropdownValue[getCurrentYearOrMonth("month", 1)],
  );
  const [selectedYear, setSelectedYear] = useState<any>(
    yearsDataDropdownValue[0],
  );
  const [selectedUsers, setSelectedUsers] = useState<DropdownValue[]>([]);
  const [selectedRoles, setselectedRoles] = useState<DropdownValue[]>([]);

  const {
    admin: {
      users: {
        data: { users },
      },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  useEffect(() => {
    handleTableSearch && handleTableSearch("selectedMonth", selectedMonth?.id);
  }, [handleTableSearch, selectedMonth]);

  useEffect(() => {
    handleTableSearch && handleTableSearch("selectedYear", selectedYear?.id);
  }, [handleTableSearch, selectedYear]);

  useEffect(() => {
    handleTableSearch &&
      handleTableSearch("selectedUsers", JSON.stringify(selectedUsers));
  }, [handleTableSearch, selectedUsers]);

  useEffect(() => {
    handleTableSearch &&
      handleTableSearch("selectedRoles", JSON.stringify(selectedRoles));
  }, [handleTableSearch, selectedRoles]);

  const usersDropdownValue = users.map((user: UsersListInterface) => {
    return {
      id: user.userId,
      label: user.userName,
    };
  });

  return (
    <div className="d-flex justify-content-center">
      <Dropdown
        className="mb-4 me-2"
        title="Selecteaza luna"
        data={monthsDataDropdownValue}
        singleValue={selectedMonth}
        setSingleValue={setSelectedMonth}
      />

      <Dropdown
        className="mb-4 me-2"
        title="Selecteaza anul"
        data={yearsDataDropdownValue}
        singleValue={selectedYear ?? yearsDataDropdownValue[0]}
        setSingleValue={setSelectedYear}
      />

      <Dropdown
        className="mb-4 me-2"
        title="Utilizatori"
        data={usersDropdownValue}
        multipleValues={selectedUsers}
        setMultipleValues={setSelectedUsers}
      />

      <Dropdown
        className="mb-4 me-2"
        title="Roluri"
        data={usersRoleDropdownValue}
        multipleValues={selectedRoles}
        setMultipleValues={setselectedRoles}
      />

      {/* <Dropdown
        className="mb-4 me-2"
        title="Categorie utilizator"
        data={monthsDataDropdownValue}
        singleValue={selectedMonth}
        setSingleValue={setSelectedMonth}
      /> */}
    </div>
  );
}

export default DropdownsPageHeader;
