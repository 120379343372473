import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { DepositStockInterface } from "../../../../redux/interfaces/inventory.interface";
import { dateToString } from "../../../../utils/dateAndTime";

export const inventoryDepositStocksTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Denumire produs",
    value: "productName",
    withSearch: true,
    minW: 200,
    maxW: 300,
  },
  {
    label: "Cod produs",
    value: "productCode",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Lot",
    value: "lot",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Data expirare",
    value: "dataExpirare",
    minW: 125,
  },
  {
    label: "Cantitate",
    value: "totalCantitate",
    minW: 50,
  },
  {
    label: "Cladire",
    value: "building",
    withSearch: {
      withoutSearchIcon: true,
    },
    minW: 30,
  },

  {
    label: "Rand",
    value: "row",
    withSearch: {
      withoutSearchIcon: true,
    },
    minW: 30,
  },
  {
    label: "Coloana",
    value: "column",
    withSearch: {
      withoutSearchIcon: true,
    },
    minW: 30,
  },
  {
    label: "Raft",
    value: "shelf",
    withSearch: {
      withoutSearchIcon: true,
    },
    minW: 30,
  },
  {
    label: "Celula",
    value: "cell",
    withSearch: {
      withoutSearchIcon: true,
    },
    minW: 30,
  },
  {
    label: "Alee",
    value: "alley",
    withSearch: {
      withoutSearchIcon: true,
    },
    minW: 30,
  },
  {
    label: "Insula",
    value: "island",
    withSearch: {
      withoutSearchIcon: true,
    },
    minW: 30,
  },
];

export const getInventoryDepositStocksTableData: any = (
  inventoryDepositStocksList: DepositStockInterface[],
) =>
  inventoryDepositStocksList?.map((inventoryDepositStocks) => {
    return {
      id: inventoryDepositStocks.idActivCel,
      crudData: inventoryDepositStocks,
      data: [
        {
          title: inventoryDepositStocks.numeProdusErp,
        },
        {
          title: inventoryDepositStocks.codProdus,
        },
        {
          title: inventoryDepositStocks.lot,
        },
        {
          badges: [
            {
              badgeText: `${dateToString(
                inventoryDepositStocks.dataExpirare ?? "-",
              )}`,
              badgeColor: "light-danger",
            },
          ],
        },
        {
          title: inventoryDepositStocks.cantitate,
        },
        {
          title: inventoryDepositStocks.cladire,
        },
        {
          title: inventoryDepositStocks.rand,
        },
        {
          title: inventoryDepositStocks.coloana,
        },
        {
          title: inventoryDepositStocks.polita,
        },
        {
          title: inventoryDepositStocks.celula,
        },
        {
          title: inventoryDepositStocks.alee,
        },
        {
          title: inventoryDepositStocks.insula,
        },
      ],
    };
  });
