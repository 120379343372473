import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const fetchUsers = createAsyncThunk(
  "/admin/users",
  async (
    transferData: {
      page: number;
      perPage: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage } = transferData;
      const { data } = await apiRequest(
        `/showAllUsers?page=${page ?? 1}&per_page=${perPage ?? 5}`,
        "GET",
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/users", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserDetails = createAsyncThunk(
  "/admin/user",
  async (userId: string, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/returnUser/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const postUserDetails = createAsyncThunk(
  "/admin/user",
  async (
    {
      userId,
      userDetails,
    }: {
      userId: string | null;
      userDetails: {
        userName: string;
        firstName: string;
        lastName: string;
        password: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/register`, "POST", {
        userId,
        ...userDetails,
      });

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeleteUser = createAsyncThunk(
  "/admin/deleteUser",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/deleteUser/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/deleteUser", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsWeb = createAsyncThunk(
  "/admin/fetchUserPermissionsWeb",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteWebApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsWeb = createAsyncThunk(
  "/admin/postUserPermissionsWeb",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
        subRoutes: {
          idRoute: number;
          isChecked: boolean;
        }[];
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("addUserRoutes", "POST", transferData);

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsMobile = createAsyncThunk(
  "/admin/fetchUserPermissionsMobile",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteMobileApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsMobile = createAsyncThunk(
  "/admin/postUserPermissionsMobile",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "addUserRoutesMobile",
        "POST",
        transferData,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

interface GetCellsNomenclatureInterface {
  cladire?: string;
  page?: number;
  per_page?: number;
  alee?: string;
  celula?: string;
  coloana?: string;
  insula?: string;
  rand?: string;
  polita?: string;
  zona?: number;
  type?: number;
}

export const getCellsNomenclature = createAsyncThunk(
  "/admin/getCellsNomenclature",
  async (
    filters: GetCellsNomenclatureInterface,
    { rejectWithValue, dispatch },
  ) => {
    const {
      cladire = "",
      page = "",
      per_page = "",
      alee = "",
      celula = "",
      coloana = "",
      insula = "",
      rand = "",
      polita = "",
      zona = "",
      type = "",
    } = filters;

    try {
      const { data } = await apiRequest(
        `/cellsNomenclature?page=${page}&per_page=${per_page}&cladire=${cladire}&alee=${alee}&celula=${celula}&coloana=${coloana}&insula=${insula}&rand=${rand}&polita=${polita}&zona=${zona}&type=${type}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/admin/getCellsNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateCellNomenclature = createAsyncThunk(
  "/admin/postCreateOrUpdateCellNomenclature",
  async (
    {
      params,
      callBack,
    }: {
      params: any;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/createOrUpdateCell", "POST", params);
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/admin/postCreateOrUpdateCellNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getSelectCellNomenclature = createAsyncThunk(
  "/admin/getSelectCellNomenclature",
  async (idCelula: number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/getSelectCell/${idCelula}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/admin/getSelectCellNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchDeleteCellNomenclature = createAsyncThunk(
  "/admin/deleteCellNomenclature",
  async (
    {
      idCelula,
    }: {
      idCelula: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/deleteCell/${idCelula}`, "GET");
      dispatch(getCellsNomenclature({}));
      return data;
    } catch (err: any) {
      console.warn("/admin/deleteCellNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPrintCellNomenclature = createAsyncThunk(
  "/admin/getPrintCellNomenclature",
  async (
    {
      idCelula,
    }: {
      idCelula: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/printCellBarCode/${idCelula}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/admin/getPrintCellNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
