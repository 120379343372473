import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";

import { UsersActivityInterface } from "../../../../redux/interfaces/reports.interface";

export const usersActivityTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Username",
    value: "userName",
    minW: 125,
  },
  {
    label: "Prenume",
    value: "prenume",
    minW: 125,
  },
  {
    label: "Nume",
    value: "nume",
    minW: 125,
  },
  {
    label: "Taskuri putaway",
    value: "putAway",
    minW: 125,
  },
  {
    label: "Taskuri rearanjare",
    value: "rearanjare",
    minW: 125,
  },
  {
    label: "Taskuri umplere",
    value: "umplere",
    minW: 125,
  },
  {
    label: "Comenzi colectate",
    value: "comenziColectate",
    minW: 125,
  },
  {
    label: "Comenzi verificate",
    value: "comenziVerificate",
    minW: 125,
  },
  {
    label: "Produse receptionate",
    value: "produseReceptionate",
    minW: 125,
  },
  {
    label: "Produse solutionate",
    value: "produseSolutionate",
    minW: 125,
  },
  {
    label: "Receptii deschise",
    value: "receptiiDeschise",
    minW: 125,
  },
];

export const getUsersActivityTableData: any = (
  reportsUserActivityList: UsersActivityInterface[],
) =>
  reportsUserActivityList?.map((item) => {
    return {
      id: item.idUser,
      crudData: item,
      data: [
        {
          title: item.userName,
        },
        {
          title: item.prenume,
        },
        {
          title: item.nume,
        },
        {
          title: item.putAway ?? "-",
        },
        {
          title: item.rearanjare ?? "-",
        },
        {
          title: item.umplere ?? "-",
        },
        {
          title: item.comenziColectate ?? "-",
        },
        {
          title: item.comenziVerificate ?? "-",
        },
        {
          title: item.produseReceptionate ?? "-",
        },
        {
          title: item.produseSolutionate ?? "-",
        },
        {
          title: item.receptiiDeschise ?? "-",
        },
      ],
    };
  });
